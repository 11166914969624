import React, { Fragment } from 'react';

import ModelIndicator from '../../../services/ModelIndicator';
import ModelReportBuilder from '../../../services/ModelReportBuilder';
import { IndicatorPeriod } from '../mahutDemo.types';
import IssueHistory from '../../../entities/issueHistory';

import "./riskPerformanceDetails.css"
import FormatUtils from '../../../services/formatUtils';

type RiskPerformanceDetailsProps = {
  issue: IssueHistory;
  indicator: ModelIndicator;
  period: IndicatorPeriod;
}


/**
 * Displays the risk performance details.
 * TODO Consider sharing the inflection report rather than regenerating
 * @param props Details properties
 * @returns Details root element
 */
export default function RiskPerformanceDetails(props: RiskPerformanceDetailsProps): React.ReactElement {
  const report = React.useMemo(
    () => ModelReportBuilder.build(props.indicator, props.period),
    [props.indicator, props.period]
  );
  return (
    <div className="risk-perf-details">
      { /* TODO Restore once we've fixed the market risk calculation 
      <div className="rpd-main-stats">
        <span className="rpd-label">Market:</span>
        <span className="rpd-value">{ FormatUtils.formatPercent(report.summary.marketRisk) }%</span>
        <span className="rpd-label">Model:</span>
        <span className="rpd-value">{ FormatUtils.formatPercent(report.summary.modelRisk) }%</span>
      </div>
      */ }
      { report.summaryBreakdown.length > 0 &&
        <div className="rpd-main-breakdown">
          <span className="mdp-title">Period</span>
          <span className="mdp-title">Market</span>
          <span className="mdp-title">Model</span>
          { report.summaryBreakdown.map(rep => (
            <Fragment key={ rep.label }>
              <span>{ rep.label }</span>
              <span>{ FormatUtils.formatPercent(rep.marketRisk) }%</span>
              <span>{ FormatUtils.formatPercent(rep.modelRisk) }%</span>
            </Fragment>
          ))}
        </div>
      }
    </div>
  )
}
