import IssueHistory from "../entities/issueHistory";

const KEY_USED = "issueUsed";
const KEY_ISSUE_PRE = "issueHistory_";
const MAX_CACHE_SIZE = 30;

export default class IssueCacheService {
    /**
     * Gets an issue from the cache, if present. Marks the current time in the
     * LRU cache.
     * @param symbol Symbol to fetch
     * @returns Issue history fetched from cache or null if not present
     */
    static getIssue(symbol: string): IssueHistory | null {
        // Check the cache
        const normSymbol = symbol.toUpperCase();
        const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
        if (!lru[normSymbol]) {
            // Symbol not cached
            return null;
        }

        // Get the issue from the cache
        const res = JSON.parse(localStorage.getItem(KEY_ISSUE_PRE + normSymbol) ?? "null");
        if (!res) {
            return null;
        }

        // Mark symbol as used now
        lru[normSymbol] = Date.now();
        localStorage.setItem(KEY_USED, JSON.stringify(lru));

        // Type conversion required for dates
        if (!res.name) {
            // Convert old record format
            res.name = res.description; 
            res.description = undefined;
        }
        const history = res as IssueHistory;
        history.daily?.forEach(day => day.date = new Date(day.date))
        return history;
    }

    /**
     * Saves the issue to the cache. Updates the LRU, may result in old issues
     * being purged.
     * @param issue Issue to cache
     */
    static saveIssue(issue: IssueHistory): void {
        // First attempt to update the cached symbol
        const normSymbol = issue.symbol.toUpperCase();
        const issueKey = KEY_ISSUE_PRE + normSymbol;
        const serializedIssue = JSON.stringify(issue);
        do {
            try {
                // Write the issue to the cache
                localStorage.setItem(issueKey, serializedIssue);

                // Update recently used list
                const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
                lru[normSymbol] = Date.now();
                localStorage.setItem(KEY_USED, JSON.stringify(lru));
                while (this.bumpIssueFromCache(true));
                return;
            } catch(err: any) {
                if (err.name !== "QuotaExceededError") {
                    // Unexpected failure, rethrow
                    throw err;
                }
            }
        } while (this.bumpIssueFromCache());
    }

    /**
     * Removes the oldest symbol from the cache. If the checkMax param is true,
     * this won't do anything if the cache size is not greater than the maximum.
     * @param checkMax Should test length against max
     * @returns Bumped flag
     */
    private static bumpIssueFromCache(checkMax: boolean = false): boolean {
        // Get the current cache usage
        const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
        const length = Object.keys(lru).length;
        if (!length || (checkMax && length <= MAX_CACHE_SIZE)) {
            // No need to bump
            return false;
        }

        // Find the oldest entry
        const oldestSymbol = Object.keys(lru).reduce((a, c) => lru[a] < lru[c] ? a : c);
        try {
            // Delete the found symbol
            delete lru[oldestSymbol];
            localStorage.removeItem(KEY_ISSUE_PRE + oldestSymbol);
            localStorage.setItem(KEY_USED, JSON.stringify(lru));
            return true;
        } catch (err: any) {
            // Failed to delete the bumped issue
            console.error(`Failed to delete oldest symbol :${oldestSymbol} from local cache: ${err.message}`);
            return false;
        }
    }
}