import SymbolSearch from "../../entities/symbolSearch";
import IIssueParser from "./issueParser";
import { SelectResult } from "./issueSymbolParser";

import { commodityParser } from "./commodityParser";
import { cryptoParser } from "./cryptoParser";
import { forexParser } from "./forexParser";

/**
 * Combines all issue parsers logically.
 */
const issueParsers: IIssueParser = {
  select(symbol: string): false | SelectResult {
    return (
      commodityParser.select(symbol) ||
      cryptoParser.select(symbol) ||
      forexParser.select(symbol)
    );
  },

  isValidIssue(mainIssue?: string | undefined, pairIssue?: string | undefined): boolean {
    return (
      commodityParser.isValidIssue(mainIssue, pairIssue)
      || cryptoParser.isValidIssue(mainIssue, pairIssue)
      || forexParser.isValidIssue(mainIssue, pairIssue)
    );
  },

  search(searchText: string): false | SymbolSearch {
    return (
      commodityParser.search(searchText)
      || cryptoParser.search(searchText)
      || forexParser.search(searchText)
    );
  }
};

export { issueParsers };
