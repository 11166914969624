
import React, { useState } from 'react';
import LookupSymbol from '../lookupSymbol';
import { toast } from 'react-toastify';
import IssueHistoryLoader from '../../services/issueHistoryLoader';
import IssueHistory from '../../entities/issueHistory';
import ModelIndicator from '../../services/ModelIndicator';
import { IndicatorStrategy } from '../../entities/indicatorStrategy';
import { LoginService } from '../../services/loginService';
import './landingPanel.css';
import { ModelIndicatorBuilder } from '../../services/ModelIndicatorBuilder';

interface LandingPanelProps {
    onSymbolLookup: (issue: IssueHistory, indicators: ModelIndicator[]) => void;
}

const LandingPanel: React.FC<LandingPanelProps> = ({ onSymbolLookup }) => {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const lookupSymbol = (symbol: string): void => {
        const toastId = toast.loading("Looking up issue...");
        IssueHistoryLoader.loadIssue(symbol)
            .then(loadResult => {
                if (typeof loadResult === "string") {
                    // Display error message
                    toast.update(toastId, {
                        render: loadResult,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    // Display the loaded issue
                    const loadedIssue: IssueHistory = loadResult;
                    const daily = loadedIssue.daily!;
                    toast.dismiss(toastId);
                    const indicators = [
                        new ModelIndicatorBuilder(daily, IndicatorStrategy.Daily).build(),
                        new ModelIndicatorBuilder(daily, IndicatorStrategy.ThreeDay).build(),
                        new ModelIndicatorBuilder(daily, IndicatorStrategy.Weekly).build(),
                        new ModelIndicatorBuilder(daily, IndicatorStrategy.Monthly).build(),
                        new ModelIndicatorBuilder(daily, IndicatorStrategy.ThreeMonth).build(),
                    ];
                    onSymbolLookup(loadedIssue, indicators);
                }
            });
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (await LoginService.login(username, password)) {
            setIsAuthenticated(true);
            setIsLoginOpen(false);
        } else {
            toast.error("Invalid username or password");
        }
    };

    return (
        <div className="landing-panel">
            <img
                src="drivers-logo-for-light-bkg.png"
                alt="Drivers Logo"
                className="bounce-in-top"
            />
            {isAuthenticated ? (
                <LookupSymbol onSymbol={(symbol) => lookupSymbol(symbol)} />
            ) : (
                <button className="login-btn" onClick={() => setIsLoginOpen(true)}>Login</button>
            )}
            {isLoginOpen && (
                <div className="login-popup">
                    <div className="login-popup-content">
                        <span className="close" onClick={() => setIsLoginOpen(false)}>&times;</span>
                        <h2>Login</h2>
                        <form onSubmit={handleLogin}>
                            <label htmlFor="username">Username:</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="submit">Login</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LandingPanel;
