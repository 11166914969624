import React, { Fragment } from 'react';
import ModelIndicator from '../../../services/ModelIndicator';
import { IndicatorPeriod } from '../mahutDemo.types';
import { Inflection } from '../../../entities/inflectionPoint';
import { ActiveStrategies, IndicatorStrategy } from '../../../entities/indicatorStrategy';
import ChromeService from '../../../services/chromeService';
import ModelReportBuilder from '../../../services/ModelReportBuilder';
import ModelReport from '../../../services/ModelReport';


/** Specifies the capital exposure chart's properties. */
type MarketSentimentChartProps = {
  period: IndicatorPeriod;
  indicators: ModelIndicator[];
  strategy: IndicatorStrategy;
}

/** Specifies the display data for one heatmap row. */
type HeatmapRow = {
  label: string;
  levels: Array<number | null>;
};

/** Specifies the number of cells per heatmap row. */
const CELLS_PER_ROW = 50;

/** Specifies the maximum number of rows to display */
const MAX_ROWS = 15;

/**
 * Displays the market sentiment chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function MarketSentimentChart(props: MarketSentimentChartProps): React.ReactElement {
  // Memoize the heatmap data
  const heatmapData = React.useMemo(() => {
    const report = ModelReportBuilder.build(props.indicators[0], props.period);
    return generateHeatmap(props, report).splice(-MAX_ROWS).reverse();
  }, [props]);
  if (!heatmapData.length) {
    // Nothing to display
    // Note: weird casting makes Typescript happy
    return false as unknown as React.ReactElement;
  }

  // Render the heatmap
  const gridTemplateColumns = `max-content repeat(${CELLS_PER_ROW}, 1fr)`;
  return (
    <div style={{position: "absolute", inset: "4pt", display: "grid", gridTemplateColumns}}>
    {
      heatmapData.map(heatmapRow =>
        <Fragment key={heatmapRow.label}>
          <div style={{textAlign: "right"}}>{ heatmapRow.label }&nbsp;</div>
          {
            heatmapRow.levels.map((level, idx) => {
              const backgroundColor = ChromeService.getHeatmapColor(level);
              return <div key={`['${heatmapRow.label}'][${idx}]`} style={{backgroundColor}}></div>
            })
          }
        </Fragment>
      )
    }
    </div>
  );
}

/**
 * Generates the data to display for this heatmap.
 * @param props Details about the chart whose heatmap we're displaying
 * @param report Aggregated indicator details
 * @returns Heatmap rows to display
 */
function generateHeatmap(props: MarketSentimentChartProps, report: ModelReport): Array<HeatmapRow> {
  // Determine vertical axis step
  if (report.groupByMonths > 3) {
    // Calculate by year
    return generateYear(props, report);
  } else if (report.groupByMonths === 3) {
    // Calculate by quarter
    return generateQuarter(props, report);
  } else {
    // Calculate by month
    return generateMonth(props, report);
  }
}

/**
 * Generates heatmap row for date range.
 * @param indicators Indicator data provides source for visualization
 * @param isActive Active (vs passive) strategy
 * @param label Label for generated row
 * @param rowStartDate Start date for row
 * @param rowEndDate Exclusive end date for row
 * @returns Generated row data
 */
function generateRow(indicators: ModelIndicator[], isActive: boolean, label: string,
    rowStartDate: Date, rowEndDate: Date): HeatmapRow {
  // Create a new row for the current year
  const anyIndicator = indicators[0];
  const firstDate = anyIndicator.closeDate[0];
  const lastDate = anyIndicator.closeDate.slice(-1)[0];
  const buildRow = new Array<number | null>();
  const cursors = indicators.map(ind => new Inflection.ForwardCursor(rowStartDate, ind));
  const anyCursor = cursors[0];
  while (anyCursor.date < rowEndDate) {
    // Verify dates represent actual data
    if ((anyCursor.date < firstDate) || (anyCursor.date > lastDate)) {
      buildRow.push(null);
      cursors.forEach(cur => cur.next())
      continue;
    }

    // Calculate level for current cursor
    buildRow.push(cursors.reduce((acc, cur, idx) => {
      const point = cur.next();
      switch(point.type) {
        case Inflection.Type.Buy:
          return acc + (isActive ? 5 - idx : idx + 1);
        case Inflection.Type.Sell:
          return acc + (isActive ? idx - 5 : -idx - 1);
        default:
          return acc;
      }
    }, 0));
  }

  // Normalize to the same number of cells per row
  const step = CELLS_PER_ROW / buildRow.length;
  const row: HeatmapRow = { label, levels: [] };
  for (let ridx = 0; ridx < CELLS_PER_ROW; ++ridx) {
    const bidx = Math.round(ridx / step);
    if (step > 1) {
      // Spread out cells
      row.levels.push(buildRow[bidx]);
    } else {
      // Average adjacent cells
      const bidx1 = Math.round((ridx + 1) / step);
      const bavg = buildRow.slice(bidx, bidx1).filter(level => level !== null) as Array<number>;
      row.levels.push(bavg.length ? bavg.reduce((a, c) => a + c) / bavg.length : null);
    }
  }

  // Return the row
  return row;
}

/**
 * Generates annual heatmap view.
 * @param props Chart properties
 * @param report Aggregated indicator details
 * @returns Heatmap representation by year
 */
function generateYear(props: MarketSentimentChartProps, report: ModelReport): Array<HeatmapRow> {
  const isActive = ActiveStrategies.includes(props.strategy);
  const res: Array<HeatmapRow> = [];
  let rowDate = report.startDate;
  while (!!rowDate && (rowDate.getUTCFullYear() <= report.endDate.getUTCFullYear())) {
    // Generate row for range
    const label = Number(rowDate.getUTCFullYear()).toString();
    const nextDate = new Date(rowDate);
    nextDate.setUTCFullYear(nextDate.getFullYear() + 1);
    res.push(generateRow(props.indicators, isActive, label, rowDate, nextDate));

    // Next row
    rowDate = nextDate;
  }

  // Return the collected rows
  return res;
}

/**
 * Generates quarterly heatmap view.
 * @param props Chart properties
 * @param report Aggregated indicator details
 * @returns Heatmap representation by year
 */
function generateQuarter(props: MarketSentimentChartProps, report: ModelReport): Array<HeatmapRow> {
  const isActive = ActiveStrategies.includes(props.strategy);
  const res: Array<HeatmapRow> = [];
  let rowDate = report.startDate;
  while (!!rowDate && (rowDate <= report.endDate)) {
    // Generate row for range
    const label = `Q${Math.floor(rowDate.getMonth() / 3) + 1} - ${rowDate.toLocaleString('default', {year: "numeric"})}`;
    const nextDate = new Date(rowDate);
    nextDate.setUTCMonth(nextDate.getMonth() + 3);
    res.push(generateRow(props.indicators, isActive, label, rowDate, nextDate));

    // Next row
    rowDate = nextDate;
  }

  // Return the collected rows
  return res;
}

/**
 * Generates monthly heatmap view.
 * @param props Chart properties
 * @param report Aggregated indicator details
 * @returns Heatmap representation by year
 */
function generateMonth(props: MarketSentimentChartProps, report: ModelReport): Array<HeatmapRow> {
  const isActive = ActiveStrategies.includes(props.strategy);
  const res: Array<HeatmapRow> = [];
  const startDate = report.startDate;
  let rowDate = startDate;
  let nextMonth = 1;
  while (!!rowDate && (rowDate < report.endDate)) {
    // Need date for next range to determine the end of this one
    // Note the complicated logic to handle Jan 31 to Feb 28 type cases
    const nextDate = new Date(startDate);
    nextDate.setUTCMonth(startDate.getUTCMonth() + nextMonth);
    if (nextDate.getUTCDate() !== startDate.getUTCDate()) {
      nextDate.setUTCDate(0);
    }

    // Generate row for range
    const label = rowDate.toLocaleString('default', {month: "long", year: "numeric"});
    res.push(generateRow(props.indicators, isActive, label, rowDate, nextDate));

    // Next row
    rowDate = nextDate;
    ++nextMonth;
  }

  // Return the collected rows
  return res;
}
