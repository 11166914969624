export enum IndicatorStrategy {
    Daily = "M0 Model",  
    ThreeDay = "M1 Model",
    Weekly = "M2 Model",
    Monthly = "Monthly",
    ThreeMonth = "3-Month"
}

export const ActiveStrategies = [
    IndicatorStrategy.Daily,
    IndicatorStrategy.ThreeDay,
];