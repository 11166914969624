import { IndicatorPeriod } from "../components/mahutDemo/mahutDemo.types";
import { Inflection } from "../entities/inflectionPoint";
import ModelIndicator from "./ModelIndicator";
import DateUtils from "./dateUtils";
import ModelReport from "./ModelReport";

interface PeriodSummary {
  label: string;
  marketPct: number;
  modelPct: number;
  marketRisk: number;
  modelRisk: number;
}

// Utility function to filter closing data based on date range
const filterClsData = (
  indicator: ModelIndicator,
  startDate: Date,
  endDate: Date
): number[] => {
  return indicator.closeValue.filter((value, index) => {
    const date = indicator.closeDate[index];
    return date >= startDate && date <= endDate;
  });
};

// Utility function to calculate market risk from filtered closing data
const calculateMarketRisk = (clsData: number[]): number => {
  let marketRisk = 0;
  let peakValue = -Infinity;

  clsData.forEach((value) => {
    // Update peak value if the current point is higher than the previous peak
    if (value > peakValue) {
      peakValue = value;
    }

    // Calculate drawdown from the peak value
    const drawdown = (value - peakValue) / peakValue;

    // Update marketRisk to the maximum drawdown (most negative value)
    if (drawdown < marketRisk) {
      marketRisk = drawdown;
    }
  });

  return marketRisk;
};

/**
 * Builds a ModelReport object based on the provided ModelIndicator, and IndicatorPeriod.
 *
 * @param indicator - The ModelIndicator, object to analyze.
 * @param period - The IndicatorPeriod object defining the start and end dates for the analysis.
 *
 * @returns A ModelReport object containing various metrics and calculations based on the provided data.
 *
 * @see ModelReport
 */
export default class ModelReportBuilder {
  static build(
    indicator: ModelIndicator,
    period: IndicatorPeriod
  ): ModelReport {
    const { startDate, endDate, groupByMonths } = period.getDates(indicator);
    const start = startDate;
    const end = endDate;
    const group = groupByMonths;

    // Fetch inflections starting from startDate
    const allInflectionsFromStartDate = indicator.getInflections(start);

    // Filter out inflections that occur after endDate
    const inflections = allInflectionsFromStartDate.filter(
      (inf) => inf.date <= end
    ); 

    // Get the general gains
    const summary = this.calculateGains(
      "Overall",
      indicator,
      inflections,
      start,
      end
    );
    const summaryBreakdown: PeriodSummary[] = [];

    // Determine the segmentation of data by time
    this.determineSegmentation(
      indicator,
      summaryBreakdown,
      inflections,
      start,
      end,
      group
    );

    // Calculate capital growth results
    const capitalGrowth = this.calculateCapitalGrowthData(
      indicator,
      start,
      end,
      inflections
    );
    const capitalGrowthMarket = capitalGrowth.marketLine;
    const capitalGrowthModel = capitalGrowth.modelLine;
    const cagrMarket = capitalGrowth.marketCagr;
    const cagrModel = capitalGrowth.modelCagr;
    const driversScore = this.calculateDriversScore(
      indicator,
      capitalGrowthMarket,
      capitalGrowthModel,
      start,
      end
    );

    // Calculate Sharpe Ratios
    const modelSharpeRatio = this.getModelSharpeRatio(
      indicator,
      start,
      end,
      inflections,
      cagrModel
    );

    const marketSharpeRatio = this.getMarketSharpeRatio(
      indicator,
      start,
      end,
      inflections,
      cagrMarket
    );

    const modelReport = new ModelReport();
    Object.assign(modelReport, {
      startDate: start,
      endDate: end,
      groupByMonths: group,
      inflections: inflections,
      summary: summary,
      summaryBreakdown: summaryBreakdown,
      capitalGrowthMarket: capitalGrowthMarket,
      capitalGrowthModel: capitalGrowthModel,
      cagrMarket: cagrMarket,
      cagrModel: cagrModel,
      marketDriversScore: driversScore.marketDriversScore,
      modelDriversScore: driversScore.modelDriversScore,
      modelSharpeRatio: modelSharpeRatio,
      marketSharpeRatio: marketSharpeRatio,
    });

    return modelReport;
  }

  /**
   * Calculates the capital growth data for the given indicator within the specified date range.
   *
   * @param indicator - The ModelIndicator, object representing the financial data.
   * @param startDate - The start date of the period for which the capital growth data needs to be calculated.
   * @param endDate - The end date of the period for which the capital growth data needs to be calculated.
   * @param inflections - The inflection points (buy/sell signals) within the specified date range.
   * @param initialSharePrice - The initial share price for the capital growth calculation. Default is 1000000.
   *
   * @returns An object containing the capital growth data for the market and the model.
   * The object includes arrays for the market and model lines, monthly market and model lines, and the CAGR (Compound Annual Growth Rate) for both.
   */
  private static calculateCapitalGrowthData(
    indicator: ModelIndicator,
    startDate: Date,
    endDate: Date,
    inflections: Inflection.Point[],
    initialSharePrice: number = 100000
  ) {
    const { startIdx, endIdx } = indicator.indexRange(startDate, endDate);
    const clsData: number[] = indicator.closeValue.slice(startIdx, endIdx + 1);
    const clsDates: Date[] = indicator.closeDate.slice(startIdx, endIdx + 1);

    let modelShares = 0;
    let modelCash = initialSharePrice;
    const shareBasis = initialSharePrice / clsData[0];

    // Check for ongoing Buy inflection before the period
    const isLastInflectionBuy = this.isLastInflectionBuyBeforePeriod(
      startDate,
      indicator
    );
    if (isLastInflectionBuy) {
      modelShares = initialSharePrice / clsData[0];
      modelCash = 0;
    }

    const marketLine = clsData.map((close, index) => ({
      date: clsDates[index],
      value: close * shareBasis,
    }));
    const modelLine: { date: Date; value: number }[] = [];

    clsData.forEach((close, index) => {
      const dayDate = clsDates[index];
      const inflection = inflections.find(
        (inf) => inf.date.getTime() === dayDate.getTime()
      );
      let isLong = modelShares > 0;

      if (inflection) {
        if (inflection.type === Inflection.Type.Buy && !isLong) {
          modelShares = modelCash / close;
          modelCash = 0;
        } else if (inflection.type === Inflection.Type.Sell && isLong) {
          modelCash = modelShares * close;
          modelShares = 0;
        }
      }

      const value = modelShares * close + modelCash;
      modelLine.push({ date: dayDate, value });
    });

    // Filter the modelLine and marketLine to get the value at the end of each month
    const monthlyModelLine = modelLine.filter((data, index, self) => {
      return data.date.getMonth() !== self[index + 1]?.date.getMonth();
    });

    const monthlyMarketLine = marketLine.filter((data, index, self) => {
      return data.date.getMonth() !== self[index + 1]?.date.getMonth();
    });

    // Calculate current values
    const currentMarketValue = marketLine[marketLine.length - 1].value;
    const currentModelValue = modelLine[modelLine.length - 1].value;

    // calculate number of years for the period
    const totalMonths = DateUtils.monthsDiff(startDate, endDate);
    const years = totalMonths / 12;

    // Calculate CAGR
    const marketCagr =
      (currentMarketValue / initialSharePrice) ** (1 / years) - 1;
    const modelCagr =
      (currentModelValue / initialSharePrice) ** (1 / years) - 1;

    return {
      marketLine: marketLine.map((data) => data.value),
      modelLine: modelLine.map((data) => data.value),
      monthlyModelLine: monthlyModelLine.map((data) => data.value),
      monthlyMarketLine: monthlyMarketLine.map((data) => data.value),
      marketCagr: marketCagr * 100,
      modelCagr: modelCagr * 100,
    };
  }

  /**
   * Calculates the monthly returns for the model based on the capital growth data.
   *
   * @param indicator - The indicator for which the calculations are being made.
   * @param startDate - The start date for the period over which the calculations are being made.
   * @param endDate - The end date for the period over which the calculations are being made.
   * @param inflections - The inflection points for the period over which the calculations are being made.
   *
   * @returns An array of monthly returns for the model.
   */
  private static calculateMonthlyModelReturns(
    indicator: ModelIndicator,
    startDate: Date,
    endDate: Date,
    inflections: Inflection.Point[]
  ): number[] {
    const capitalGrowthData = this.calculateCapitalGrowthData(
      indicator,
      startDate,
      endDate,
      inflections
    );
    const monthlyModelLine = capitalGrowthData.monthlyModelLine;
    const monthlyReturns = [];
    const initialValue = monthlyModelLine[0];

    // Calculate the return for the initial month
    const initialReturn = (monthlyModelLine[0] - initialValue) / initialValue;
    monthlyReturns.push(initialReturn);

    // Calculate returns for the remaining months
    for (let i = 1; i < monthlyModelLine.length; i++) {
      const monthlyReturn =
        (monthlyModelLine[i] - monthlyModelLine[i - 1]) /
        monthlyModelLine[i - 1];
      monthlyReturns.push(monthlyReturn);
    }

    return monthlyReturns;
  }

  /**
   * Calculates the monthly returns for the market based on the capital growth data.
   *
   * @param indicator - The indicator for which the calculations are being made.
   * @param startDate - The start date for the period over which the calculations are being made.
   * @param endDate - The end date for the period over which the calculations are being made.
   * @param inflections - The inflection points for the period over which the calculations are being made.
   *
   * @returns An array of monthly returns for the market.
   */
  private static calculateMonthlyMarketReturns(
    indicator: ModelIndicator,
    startDate: Date,
    endDate: Date,
    inflections: Inflection.Point[]
  ): number[] {
    const capitalGrowthData = this.calculateCapitalGrowthData(
      indicator,
      startDate,
      endDate,
      inflections
    );
    const monthlyMarketLine = capitalGrowthData.monthlyMarketLine;
    const monthlyReturns = [];
    const initialValue = monthlyMarketLine[0];

    // Calculate the return for the initial month
    const initialReturn = (monthlyMarketLine[0] - initialValue) / initialValue;
    monthlyReturns.push(initialReturn);

    // Calculate returns for the remaining months
    for (let i = 1; i < monthlyMarketLine.length; i++) {
      const monthlyReturn =
        (monthlyMarketLine[i] - monthlyMarketLine[i - 1]) /
        monthlyMarketLine[i - 1];
      monthlyReturns.push(monthlyReturn);
    }

    return monthlyReturns;
  }

  /**
   * Calculates the Sharpe Ratio for the model based on the given parameters.
   *
   * @param indicator - The indicator for which the calculations are being made.
   * @param startDate - The start date for the period over which the calculations are being made.
   * @param endDate - The end date for the period over which the calculations are being made.
   * @param inflections - The inflection points for the period over which the calculations are being made.
   * @param cagrModel - The compound annual growth rate (CAGR) for the model.
   * @param riskFreeRate - The risk-free rate of return, default is 0.02.
   *
   * @returns The Sharpe Ratio for the model.
   */
  static getModelSharpeRatio(
    indicator: ModelIndicator,
    startDate: Date,
    endDate: Date,
    inflections: Inflection.Point[],
    cagrModel: number,
    riskFreeRate: number = 0.02
  ): number {
    // Get monthly returns
    const monthlyReturns = this.calculateMonthlyModelReturns(
      indicator,
      startDate,
      endDate,
      inflections
    );

    // Calculate average monthly return
    const avgMonthlyReturn =
      monthlyReturns.reduce((acc, r) => acc + r, 0) / monthlyReturns.length;

    // Calculate standard deviation of monthly returns
    const stdDev = Math.sqrt(
      monthlyReturns
        .map((r) => Math.pow(r - avgMonthlyReturn, 2))
        .reduce((acc, v) => acc + v, 0) / monthlyReturns.length
    );

    // Annualize the average monthly return and standard deviation
    const monthsPerYear = 12;
    const annualStdDev = stdDev * Math.sqrt(monthsPerYear);

    // Calculate Sharpe Ratio using the annualized return and standard deviation
    const modelSharpeRatio = (cagrModel / 100 - riskFreeRate) / annualStdDev;

    return modelSharpeRatio;
  }
  /**
   * Calculates the Sharpe Ratio for the market based on the given parameters.
   *
   * @param indicator - The indicator for which the calculations are being made.
   * @param startDate - The start date for the period over which the calculations are being made.
   * @param endDate - The end date for the period over which the calculations are being made.
   * @param inflections - The inflection points for the period over which the calculations are being made.
   * @param cagrMarket - The compound annual growth rate (CAGR) for the market.
   * @param riskFreeRate - The risk-free rate of return, default is 0.02.
   *
   * @returns The Sharpe Ratio for the market.
   */
  static getMarketSharpeRatio(
    indicator: ModelIndicator,
    startDate: Date,
    endDate: Date,
    inflections: Inflection.Point[],
    cagrMarket: number,
    riskFreeRate: number = 0.02
  ): number {
    // Get monthly returns
    const monthlyReturns = this.calculateMonthlyMarketReturns(
      indicator,
      startDate,
      endDate,
      inflections
    );

    // Calculate average monthly return
    const avgMonthlyReturn =
      monthlyReturns.reduce((acc, r) => acc + r, 0) / monthlyReturns.length;

    // Calculate standard deviation of monthly returns
    const stdDev = Math.sqrt(
      monthlyReturns
        .map((r) => Math.pow(r - avgMonthlyReturn, 2))
        .reduce((acc, v) => acc + v, 0) / monthlyReturns.length
    );

    // Annualize the average monthly return and standard deviation
    const monthsPerYear = 12;
    const annualStdDev = stdDev * Math.sqrt(monthsPerYear);

    // Calculate Sharpe Ratio using the annualized return and standard deviation
    const marketSharpeRatio = (cagrMarket / 100 - riskFreeRate) / annualStdDev;

    return marketSharpeRatio;
  }

  static calculateDriversScore(
    indicator: ModelIndicator,
    capitalGrowthMarket: number[],
    capitalGrowthModel: number[],
    startDate: Date,
    endDate: Date
  ): { marketDriversScore: number; modelDriversScore: number | null } {
    const initialSharePrice = 100000;
    const currentMarketValue = capitalGrowthMarket.at(-1);
    const currentModelValue = capitalGrowthModel.at(-1);

    if (currentMarketValue === undefined || currentModelValue === undefined) {
      throw new Error("Capital growth values are undefined.");
    }

    const report = indicator.getInflectionReport(startDate, endDate);
    const totalDays = report.totalDays;
    const daysOpen = report.days;

    const marketDriversScore =
      ((currentMarketValue - initialSharePrice) /
        initialSharePrice /
        totalDays) *
      100;
    const modelDriversScore =
      daysOpen > 0
        ? ((currentModelValue - initialSharePrice) /
            initialSharePrice /
            daysOpen) *
          100
        : null;

    return {
      marketDriversScore,
      modelDriversScore,
    };
  }

  /**
   * Determines the segmentation of the report based on the number of months between the start and end dates.
   *
   * @param indicator - The indicator for which the calculations are being made.
   * @param summaryBreakdown - The array to which the calculated period summaries will be added.
   * @param inflections - The inflection points for the period over which the calculations are being made.
   * @param startDate - The start date for the period over which the calculations are being made.
   * @param endDate - The end date for the period over which the calculations are being made.
   * @param groupByMonths - The number of months over which the calculations are being segmented.
   *
   * @returns {void}
   */
  private static determineSegmentation(
    indicator: ModelIndicator,
    summaryBreakdown: PeriodSummary[],
    inflections: Inflection.Point[],
    startDate: Date,
    endDate: Date,
    groupByMonths: number
  ) {
    const reportMonths = DateUtils.monthsDiff(startDate, endDate);
    if (reportMonths >= 24) {
      this.calculateGainsByYear(
        indicator,
        summaryBreakdown,
        inflections,
        startDate,
        endDate
      );
    } else if (reportMonths >= 12) {
      this.calculateGainsByQuarter(
        indicator,
        summaryBreakdown,
        inflections,
        startDate,
        endDate
      );
    } else if (reportMonths >= 3) {
      this.calculateGainsByMonth(
        indicator,
        summaryBreakdown,
        inflections,
        startDate,
        endDate
      );
    }
  }

  private static calculateGains = (
    label: string,
    indicator: ModelIndicator,
    inflections: Inflection.Point[],
    calcStart: Date,
    calcEnd: Date
  ): PeriodSummary => {
    // Easy part, calculate market performance
    const startValue = indicator.getValueStart(calcStart);
    const endValue = indicator.getValueEnd(calcEnd);
    const marketPct = startValue > 0 ? (endValue - startValue) / startValue : 0;

    let basis = startValue;
    let modelDelta = 0;
    let modelRisk = 0;
    const periodInflections = inflections.filter(
      (point) => point.date >= calcStart && point.date <= calcEnd
    );
    periodInflections.forEach((point) => {
      if (point.type === Inflection.Type.Buy) {
        // Basis for position open
        basis = point.close;
      } else if (point.type === Inflection.Type.Sell) {
        // Accumulate position close
        const positionDelta = point.close - basis;
        modelDelta += positionDelta;

        // Determine position risk
        const positionRisk = positionDelta / basis;
        modelRisk = Math.min(modelRisk, positionRisk);
      }
    });
    const lastPoint = periodInflections.at(-1);
    if (lastPoint?.type === Inflection.Type.Buy) {
      // For statistics, close the open position
      const endDelta = endValue - basis;
      modelDelta += endDelta;
      const endRisk = endDelta / basis;
      modelRisk = Math.min(modelRisk, endRisk);
    }

    // Calculate downside risk for market
    const clsData = filterClsData(indicator, calcStart, calcEnd);
    const marketRisk = calculateMarketRisk(clsData);

    // Complete the gains report
    const modelPct = startValue > 0 ? modelDelta / startValue : 0;
    return { label, marketPct, modelPct, marketRisk, modelRisk };
  };

  /**
   * Checks if the last inflection point before the given start date is a buy inflection.
   *
   * @param startDate - The start date for the period over which the check is being made.
   * @param indicator - The indicator for which the check is being made.
   *
   * @returns {boolean} - True if the last inflection point before the start date is a buy inflection, false otherwise.
   */
  public static isLastInflectionBuyBeforePeriod(
    startDate: Date,
    indicator: ModelIndicator
  ): boolean {
    // Fetch inflections before the start date
    const lastInflectionBeforeStartDate = indicator
      .getInflections(new Date(0))
      .filter((inf) => inf.date < startDate)
      .sort((a, b) => b.date.getTime() - a.date.getTime())[0];
    return lastInflectionBeforeStartDate?.type === Inflection.Type.Buy;
  }

  /**
   * Adds a period summary to the summary breakdown array.
   *
   * @param {string} label - The label for the period summary.
   * @param {ModelIndicator,} indicator - The indicator for which the calculations are being made.
   * @param {Date} calcStart - The start date for the period.
   * @param {Date} calcEnd - The end date for the period.
   * @param {PeriodSummary[]} summaryBreakdown - The array to which the period summary will be added.
   * @param {Inflection.Point[]} inflections - The inflection points for the period.
   *
   * @returns {void}
   */
  private static addPeriodSummary(
    label: string,
    indicator: ModelIndicator,
    calcStart: Date,
    calcEnd: Date,
    summaryBreakdown: PeriodSummary[],
    inflections: Inflection.Point[]
  ): void {
    const periodInflections = inflections.filter(
      (point) => point.date >= calcStart && point.date <= calcEnd
    );

    // Check if there are no inflections in the current period && last inflection is a buy
    if (
      periodInflections.length === 0 &&
      this.isLastInflectionBuyBeforePeriod(calcStart, indicator)
    ) {
      // Calculate gains using the start and end values of the period
      const startValue = indicator.getValueStart(calcStart);
      const endValue = indicator.getValueEnd(calcEnd);
      const marketPct =
        startValue > 0 ? (endValue - startValue) / startValue : 0;

      // Calculate downside risk for market
      const clsData = filterClsData(indicator, calcStart, calcEnd);
      const marketRisk = calculateMarketRisk(clsData);

      const summary: PeriodSummary = {
        label,
        marketPct,
        modelPct: marketPct,
        marketRisk,
        modelRisk: marketRisk,
      };
      summaryBreakdown.push(summary);
    } else {
      // Calculate the gains for the current period using the original calculateGains method
      const summary = this.calculateGains(
        label,
        indicator,
        inflections,
        calcStart,
        calcEnd
      );
      summaryBreakdown.push(summary);
    }
  }

  private static calculateGainsByYear = (
    indicator: ModelIndicator,
    summaryBreakdown: PeriodSummary[],
    inflections: Inflection.Point[],
    startDate: Date,
    endDate: Date
  ): void => {
    let currYear = startDate.getFullYear();
    while (currYear <= endDate.getFullYear()) {
      // Calculate current year
      const label = currYear.toFixed(0);
      const calcStart = new Date(currYear, 0, 1);
      const calcEnd = new Date(currYear, 11, 31);
      this.addPeriodSummary(
        label,
        indicator,
        calcStart,
        calcEnd,
        summaryBreakdown,
        inflections
      );
      // Process next year
      ++currYear;
    }
  };

  private static calculateGainsByQuarter = (
    indicator: ModelIndicator,
    summaryBreakdown: PeriodSummary[],
    inflections: Inflection.Point[],
    startDate: Date,
    endDate: Date
  ): void => {
    // Determine periods to calculate
    let currYear = startDate.getFullYear();
    let currQtr = Math.trunc(startDate.getMonth() / 3);
    const lastYear = endDate.getFullYear();
    const lastQtr = Math.trunc(endDate.getMonth() / 3);
    while (
      currYear < lastYear ||
      (currYear === lastYear && currQtr <= lastQtr)
    ) {
      // Calculate current quarter
      const label = `Q${currQtr + 1} ${currYear}`;
      const calcStart = new Date(currYear, currQtr * 3, 1);
      const calcEnd = new Date(currYear, (currQtr + 1) * 3, 0);
      this.addPeriodSummary(
        label,
        indicator,
        calcStart,
        calcEnd,
        summaryBreakdown,
        inflections
      );

      // Process next quarter
      if (++currQtr > 3) {
        // Process next year
        ++currYear;
        currQtr = 0;
      }
    }
  };

  private static calculateGainsByMonth = (
    indicator: ModelIndicator,
    summaryBreakdown: PeriodSummary[],
    inflections: Inflection.Point[],
    startDate: Date,
    endDate: Date
  ): void => {
    let currYear = startDate.getFullYear();
    let currMon = startDate.getMonth();
    const lastYear = endDate.getFullYear();
    const lastMon = endDate.getMonth();
    while (
      currYear < lastYear ||
      (currYear === lastYear && currMon <= lastMon)
    ) {
      // Always set calcStart to the start of the current month
      let calcStart = new Date(currYear, currMon, 1);

      // Set the end of the current month
      const calcEnd = new Date(currYear, currMon + 1, 0);
      const label = `${DateUtils.formatShortMonth(calcStart)} ${currYear}`;

      // Calculate gains for the period and add to summary
      this.addPeriodSummary(
        label,
        indicator,
        calcStart,
        calcEnd,
        summaryBreakdown,
        inflections
      );

      // Increment month
      if (++currMon > 11) {
        ++currYear;
        currMon = 0;
      }
    }
  };
}
