// loginService.ts
// TODO: This is just a placeholder for doing demos, real service coming soon

interface User {
    username: string;
    password: string;
    features: string[];
}

export class LoginService {
    static FEATURE_MO = "FEATURE_M0";

    private static users: User[] = [
        { username: "User1", password: "Drivers1", features: [] },
        { username: "user2", password: "password2", features: [LoginService.FEATURE_MO] },
        { username: "user3", password: "password3", features: [] },
        { username: "user4", password: "password4", features: [] },
        { username: "user5", password: "password5", features: [] },
    ];

    private static currentUser: User | null = null;

    static async login(username: string, password: string): Promise<boolean> {
        const validUser = LoginService.users.find(user => user.username === username && user.password === password);
        if (validUser) {
            // Login validated
            LoginService.currentUser = validUser;
            return true;
        }
    
        // Invalid login
        return false;
    };

    static getCurrentUser(): User | null {
        return LoginService.currentUser;
    }
}



// Add a new function to check if the logged-in user is "user2"
export const isUser2LoggedIn = async (username: string): Promise<boolean> => {
    return username === "user2";
};