import React, { Fragment } from 'react';

import ModelIndicator from '../../../services/ModelIndicator';
import { IndicatorPeriod } from '../mahutDemo.types';
import IssueHistory from '../../../entities/issueHistory';

import "./marketPerformanceDetails.css"
import FormatUtils from '../../../services/formatUtils';
import ModelReportBuilder from '../../../services/ModelReportBuilder';

type MarketPerformanceDetailsProps = {
  issue: IssueHistory;
  indicator: ModelIndicator;
  period: IndicatorPeriod;
}


/**
 * Displays the capita exposure details.
 * TODO Consider sharing the inflection report rather than regenerating
 * @param props Details properties
 * @returns Details root element
 */
export default function MarketPerformanceDetails(props: MarketPerformanceDetailsProps): React.ReactElement {
  const report = React.useMemo(
    () => ModelReportBuilder.build(props.indicator, props.period),
    [props.indicator, props.period]
  );
  return (
    <div className="market-perf-details">
      <div className="mpd-main-stats">
        <span className="mpd-label">Market:</span>
        <span className="mpd-value">{ FormatUtils.formatPercent(report.summary.marketPct) }%</span>
        <span className="mpd-label">Model:</span>
        <span className="mpd-value">{ FormatUtils.formatPercent(report.summary.modelPct) }%</span>
      </div>
      { report.summaryBreakdown.length > 0 &&
        <div className="mpd-main-breakdown">
          <span className="mdp-title">Period</span>
          <span className="mdp-title">Market</span>
          <span className="mdp-title">Model</span>
          { report.summaryBreakdown.map(rep => (
            <Fragment key={ rep.label }>
              <span>{ rep.label }</span>
              <span>{ FormatUtils.formatPercent(rep.marketPct) }%</span>
              <span>{ FormatUtils.formatPercent(rep.modelPct) }%</span>
            </Fragment>
          ))}
        </div>
      }
    </div>
  )
}
