/**
 * Provides dynamic styling.
 * TODO: Actually provide dynamic styling
 */
export default class ChromeService {
  static readonly graphColor = {
    positive: "#23C552", //model positive light green
    negative: "#F84F31", // model negative light red
    neutral: "#90AFFF", // grey
    marketPositive: "#91C5A0", // dark green
    marketNegative: "#F89583", // dark red
  };

  /**
   * Gets the color to display a heatmap level.
   * @param level Heatmap cell level to convert [0..15]|null
   * @returns CSS color rendered as a string
   */
  static getHeatmapColor(level: number | null): string {
    if (level === null) {
      return "#444";
    }
    const useLevel = Math.round(level);
    if (useLevel < 0) {
      const fade = Number(15 + Math.max(useLevel, -15)).toString(16);
      return `#f${fade}${fade}`;
    }
    const fade = Number(15 - Math.min(useLevel, 15)).toString(16);
    return `#${fade}f${fade}`;
  }
}
