import { IndicatorStrategy } from "../entities/indicatorStrategy";
import { Inflection } from "../entities/inflectionPoint";
import DateUtils from "./dateUtils";

/**
 * Represents the data to graph the Agora EMA indicator.
 * The `ModelIndicator` class is populated by the `ModelIndicatorBuilder` class.
 * This class does not calculate the indicator itself; it merely holds the data
 * generated by the `ModelIndicatorBuilder`.
 *
 * @see ModelIndicatorBuilder for the class responsible for building the model indicator.
 */
export default class ModelIndicator {

  // Aggregates
  count: number = 0;
  min: number = 0;
  max: number = 0;
  closeMin: number = 0;
  closeMax: number = 0;
  indMin: number = 0;
  indMax: number = 0;
  period: IndicatorStrategy = IndicatorStrategy.Daily;

  // Aggregates can only be reached by a getter
  inflections: Inflection.Point[] = [];

  // Data points
  closeDate: Date[] = [];
  closeValue: number[] = [];
  indValue: number[] = [];
  indClose: number[] = [];

  // TODO Make tr-state
  get isBuy(): boolean {
    const latest = Math.min(this.closeValue.length, this.indValue.length) - 1;
    return latest < 0 ? false : this.closeValue[latest] > this.indValue[latest];
  }

  /**
   * Gets the number of days covered in the indicator irrespective of the period.
   * Inclusive of the start and end range.
   */
  get calendarDays(): number {
    const endRange = this.closeDate.at(-1)?.getTime() ?? 0;
    const begRange = this.closeDate.at(0)?.getTime() ?? 0;
    return Math.floor((endRange - begRange) / DateUtils.MSEC_DAY) + 1;
  }

  indexFrom(date?: Date): number {
    if (!date) {
      return 0;
    }
    const idx = this.closeDate.findIndex((curr) => curr >= date);
    return idx < 0 ? this.closeDate.length : idx;
  }

  /**
   * Finds range of points covering the passed dates, inclusive.
   * @param startDate
   */
  indexRange(
    startDate: Date,
    endDate: Date
  ): { startIdx: number; endIdx: number } {
    // Test trivial case
    const length = this.closeDate.length;
    if (startDate >= endDate) {
      return { startIdx: length, endIdx: length };
    }

    // Find the start index
    let startIdx = this.closeDate.findIndex((date) => date >= startDate);
    if (startIdx === -1) {
      // If start date is beyond the available data range
      startIdx = length;
    }

    // Find the end index
    // TODO Fix findLastIndex hack when we update typescript
    let endIdx = (this.closeDate as any).findLastIndex(
      (date: Date) => date <= endDate
    );
    if (endIdx === -1) {
      // If end date is beyond the available data range
      endIdx = length;
    }
    return { startIdx, endIdx };
  }

  /** Gets the closing value at the start of a range, i.e. at or after the date. */
  getValueStart(date: Date): number {
    return this.closeValue[this.indexFrom(date)];
  }

  /** Gets the closing value at the end of a range, i.e. at or before the date. */
  getValueEnd(date: Date): number {
    const index = this.indexFrom(date);
    if (index > 0 && this.closeDate[index] > date) {
      return this.closeValue[index - 1];
    }
    return this.closeValue[index];
  }

  // TODO Get rid of this.indBuy and this.indSell turn into functions, memoize

  /**
   * Gets buy side points normalized to closing price range.
   * @param startIdx Start of indicator values to consider, inclusive
   * @param endIdx End of indicator values to consider, inclusive
   * @returns Buy side indicator normalized points
   */
  buyScaled(startIdx: number, endIdx: number): number[] {
    const indClosePeriod = this.indClose.slice(startIdx, endIdx + 1);
    const indRange = indClosePeriod.reduce((a, c) => Math.max(a, c), 0);
    const clsRange = this.closeValue
      .slice(startIdx, endIdx + 1)
      .reduce((a, c) => Math.max(a, c), 0);
    return indClosePeriod.map((val) =>
      indRange <= 0 || val < 0 ? 0 : (val / indRange) * clsRange
    );
  }

  buySide(points: number, offset?: number): number[] {
    return this.indClose
      .slice(points)
      .map((val) => Math.max(val, 0) + (offset ?? 0));
  }

  sellSide(points: number, offset?: number): number[] {
    return this.indClose
      .slice(points)
      .map((val) => Math.min(val, 0) + (offset ?? 0));
  }

  getInflections(fromDate?: Date, thruDate?: Date): Inflection.Point[] {
    return !!fromDate || !!thruDate
      ? this.inflections.filter(
          (point) =>
            (!fromDate || point.date >= fromDate) &&
            (!thruDate || point.date <= thruDate)
        )
      : this.inflections.slice(0);
  }

  /**
   * Generates an inflection report, i.e. statistics based on inflections.
   * <p>
   * The incoming source is either undefined (use all the inflections in this indicator),
   * a list of inflection points to use, or a period starting date. Note that if we're
   * long at the start of the list, we can't really make any assumptions about the period.
   * <p>
   * Result is an object with number of days long, cumulative gains for the
   * period, and largest moves up and down for the period.
   * <p>
   * TODO Should probably be added to AgoraReport
   *
   * @param source {Date | Inflection.Point[] | undefined} Inflection source
   * @returns {{days: number, gains: number, moveUp: number, moveDown: number, totalDays: number}} Report on inflections
   */
  getInflectionReport(
    source: Date | Inflection.Point[] | undefined,
    thruEndDate?: Date
  ) {
    // Determine inflections to consider based on source
    let reportInflections: Inflection.Point[];
    let reportStartDate: Date;
    if (!source) {
      reportInflections = this.inflections.slice(0);
      reportStartDate = this.closeDate[0];
    } else if (source instanceof Date) {
      reportInflections = this.getInflections(source, thruEndDate);
      reportStartDate = source;
    } else {
      reportInflections = source.slice(0);
      reportStartDate = source[0].date;
    }

    // We need to close the position at the report end date for correct calculations
    const reportEndDate =
      thruEndDate ?? this.closeDate.at(-1) ?? reportStartDate;
    if (reportInflections.at(-1)?.type === Inflection.Type.Buy) {
      // Close the position for the report
      // TODO: Any cast works around Typescript bug, remove when we upgrade
      const closeIndex = (this.closeDate as any).findLastIndex(
        (date: Date) => date <= reportEndDate
      );
      reportInflections.push({
        date: reportEndDate,
        close: this.closeValue.at(closeIndex) ?? 0,
        type: Inflection.Type.Sell,
      });
    }

    // Check if the last inflection before the start date was a buy
    const lastInflectionBeforeStart = this.inflections
      .filter((inflection) => inflection.date < reportStartDate)
      .sort((a, b) => b.date.getTime() - a.date.getTime())[0];

    const includeInitialOpenDays =
      lastInflectionBeforeStart?.type === Inflection.Type.Buy;

    // If the last inflection before the start date was a buy, add an open position at the start date
    if (includeInitialOpenDays) {
      const basisIndex = this.closeDate.findIndex(
        (date) => date >= reportStartDate
      );
      reportInflections.unshift({
        date: reportStartDate,
        close: this.closeValue[basisIndex] ?? 0,
        type: Inflection.Type.Buy,
      });
    }

    // Generate the report by accumulating the difference
    // between closed and open positions
    const report = reportInflections.reduce(
      (acc, curr, idx) => {
        if (curr.type === Inflection.Type.Sell) {
          // Determine the basis for this sell
          let from: Date;
          let basis: number;
          if (idx > 0) {
            // Prior inflection point must be a buy
            from = reportInflections[idx - 1].date;
            basis = reportInflections[idx - 1].close;
          } else {
            // First data in history
            from = this.closeDate[0];
            basis = this.closeValue[0];
            if (source instanceof Date) {
              // Find the basis at the start of the period
              const dateIndex = this.closeDate.findIndex(
                (curDate) => source <= curDate
              );
              if (dateIndex > 0) {
                from = this.closeDate[dateIndex];
                basis = this.closeValue[dateIndex];
              }
            } else if (!!source && source.length < this.inflections.length) {
              // Basis from inflection prior to array
              const inflectIndex = this.inflections.length - source.length - 1;
              if (inflectIndex >= 0) {
                const basisPoint = this.inflections[inflectIndex];
                from = basisPoint.date;
                basis = basisPoint.close;
              }
              reportStartDate = from;
            }
          }

          // Accumulate the report
          const movePct = (curr.close - basis) / basis;
          acc.days += DateUtils.daysDiff(from, curr.date);
          acc.gains += curr.close - basis;
          acc.moveUp = Math.max(acc.moveUp, movePct);
          acc.moveDown = Math.min(acc.moveDown, movePct);
        }
        return acc;
      },
      { days: 0, gains: 0, moveUp: 0, moveDown: 0, totalDays: 0 }
    );

    // Include initial open days if there was an open position at the start
    if (includeInitialOpenDays) {
      const initialOpenDays = DateUtils.daysDiff(
        reportStartDate,
        reportInflections[1]?.date || reportEndDate
      );
      report.days += initialOpenDays;
    }

    // Add total days in report period
    report.totalDays = DateUtils.daysDiff(reportStartDate, reportEndDate);
    return report;
  }

  static toJson(instance: ModelIndicator): string {
    return JSON.stringify(instance);
  }

  static fromJson(json: string): ModelIndicator {
    const data = JSON.parse(json);
    const instance = new ModelIndicator(); 
    Object.assign(instance, data); 
    return instance;
  }
}
