import React from 'react';
import IssueHistory from '../../../entities/issueHistory';
import ModelIndicator from '../../../services/ModelIndicator';
import { IndicatorPeriod } from '../mahutDemo.types';

import "./capitalExposureDetails.css";
import FormatUtils from '../../../services/formatUtils';
import ModelReportBuilder from '../../../services/ModelReportBuilder';

type CapitalExposureDetailsProps = {
  issue: IssueHistory;
  indicator: ModelIndicator;
  period: IndicatorPeriod;
}

/**
 * Displays the capita exposure details.
 * TODO Consider sharing the inflection report rather than regenerating
 * @param props Details properties
 * @returns Details root element
 */
export default function CapitalExposureDetails(props: CapitalExposureDetailsProps): React.ReactElement {
  const report = React.useMemo(() => {
    const agoraReport = ModelReportBuilder.build(props.indicator, props.period);
    return props.indicator.getInflectionReport(agoraReport.startDate, agoraReport.endDate);
  }, [props.indicator, props.period]);
  const isExposed = !!report.totalDays && !!report.days;
  const exposure = isExposed ? FormatUtils.formatPercent(report.days / report.totalDays) : "N/A";
  return (
    <div className="capital-exposure-details">
      <div className="ced-contents">
        <div className="ced-contents-label">Days Open:</div>
        <div className="ced-contents-value">{ report.days }</div>
        <div className="ced-contents-label">Days Closed:</div>
        <div className="ced-contents-value">{ report.totalDays - report.days }</div>
        <div className="ced-contents-label">Total Days:</div>
        <div className="ced-contents-value">{ report.totalDays }</div>
      </div>
      <br />
      <div className="ced-summary">
        Exposure: { exposure }%
      </div>
    </div>
  )
}
