import IssueHistory from "../entities/issueHistory";
import SymbolSearch from "../entities/symbolSearch";
import IssueCacheService from "./issueCacheService"
import StockPriceService from "./stockPriceService";
import { issueParsers } from "../parsers/issueParsers";


/**
 * Loads issue histories, from cache or API. Also searches for issues.
 */
export default class IssueHistoryLoader {
    /**
     * Loads the issue, checking the history before going to the service.
     * @param symbol Issue symbol
     * @param name Optionally override the name in the details for the issue
     * @returns Loaded history or error message
     */
    static async loadIssue(symbol: string, name?: string): Promise<IssueHistory | string> {
        // Determine if we can use data from our cache
        let issue = IssueCacheService.getIssue(symbol);
        if (!navigator.onLine) {
            return issue ?? "Offline, Unable to Load Issue History";
        }

        // Check if the cache is aged
        // TODO: timeZone changes at DST...do we care?
        let isOutOfDate = true;
        if (issue?.daily && issue.daily.length && issue.refreshDate && issue.closeTime && issue.timeZone) {
            const DAY_MS = 24 * 60 * 60 * 1000;
            const useZone = issue.timeZone.match(/([+-]\d{2})$/)?.[0] ?? "";
            const fmtZone = !useZone.length || useZone.includes(":") ? "" : ":00";
            const lastClose = `${issue.refreshDate}T${issue.closeTime}:00.000${useZone}${fmtZone}`;
            const dateClose = new Date(lastClose);
            const nextClose = dateClose.getTime() + DAY_MS;
            if (Date.now() < nextClose) {
                // We're still fresh
                isOutOfDate = false;
            }
        }

        try {
            // Do we need to load the basic issue details from the web service?
            if (!issue) {
                // Load the issue details from the web service
                issue = await StockPriceService.queryIssue(symbol, name);
                if (!issue) {
                    return `Unable To Lookup Symbol "${symbol.toUpperCase()}"`;
                }
            }
            if (isOutOfDate) {
                // Load the current history
                issue = await StockPriceService.queryHistory(issue);
                IssueCacheService.saveIssue(issue);
            }
        } catch (err: any) {
            // Actual error details logged if we need
            return issue && (issue.daily?.length ?? 0) ? issue : `Unable To Lookup Symbol "${symbol.toUpperCase()}"`;
        }

        // Provide the issue data
        return issue;
    }

    /**
     * Looks up symbols based on typed search text.
     * @param searchText Text to search on
     * @returns Search results
     */
    static async searchSymbol(searchText: string): Promise<SymbolSearch | false> {
        // Try local search for Forex, Crypto and Commodities
        let localSearch = issueParsers.search(searchText);
        if (!navigator.onLine || (localSearch && (localSearch?.exactMatch || (localSearch?.results ?? []).length > 1))) {
            // Return local search only
            return localSearch;
        }

        // Fetch from the symbol search remote API
        return StockPriceService.searchStockSymbol(searchText)
            .then(serviceResults => {
                // Combine results with local search as necessary
                if (!!serviceResults) {
                    if (!!localSearch) {
                        serviceResults.results.push(localSearch.results[0]);
                    }
                    return serviceResults;
                }
                return localSearch;
            });
    }
}