import React from 'react';

import CapitalGrowthChart, { printOptions as CGPrintOptions } from './charts/capitalGrowthChart';
import CapitalGrowthDetails from './details/capitalGrowthDetails';

import CapitalExposureChart from './charts/capitalExposureChart';
import MarketPerformanceChart from './charts/marketPerformanceChart';
import RiskPerformanceChart from './charts/riskPerformanceChart';
import MarketPerformanceDetails from './details/marketPerformanceDetails';
import RiskPerformanceDetails from './details/riskPerformanceDetails';
import CapitalExposureDetails from './details/capitalExposureDetails';
import { IndicatorPeriod } from './mahutDemo.types';
import ModelIndicator from '../../services/ModelIndicator';
import IssueHistory from '../../entities/issueHistory';
import MarketSentimentChart from './charts/marketSentimentChart';
import MarketSentimentDetails from './details/marketSentimentDetails';
import "./printableDocument.css";
import { IndicatorStrategy } from '../../entities/indicatorStrategy';

type PrintableDocumentProps = {
    period: IndicatorPeriod;
    strategy: IndicatorStrategy;
    indicator: ModelIndicator;
    issue: IssueHistory;
    indicators?: ModelIndicator[]; // Optional indicators prop
};

type PrintableDocumentState = {
    isReadyToPrint: boolean;
};

class PrintableDocument extends React.Component<PrintableDocumentProps, PrintableDocumentState> {
    constructor(props: PrintableDocumentProps) {
        super(props);
        this.state = {
            isReadyToPrint: false,
        };
    }

    componentDidMount() {
        this.setState({ isReadyToPrint: true });
        window.addEventListener('beforeprint', this.updatePageNumbers);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeprint', this.updatePageNumbers);
    }

    updatePageNumbers = () => {
        const totalPages = document.querySelectorAll('.printable-section').length;
        document.querySelectorAll('.printable-section').forEach((section, index) => {
            const footer = section.querySelector('.sectionFooter .pageNumber');
            if (footer) {
                footer.textContent = `Page ${index + 1} of ${totalPages}`;
            }
        });
    };

    handlePrint = () => {
        if (this.state.isReadyToPrint) {
            window.print();
        } else {
            console.error('The document is not ready to print.');
        }
    };

    render() {
        const { period, indicator, strategy, issue, indicators } = this.props;

        return (
            <div className="printableDocument">
                <div className="reportHeader">
                    <h3 style={{ marginRight: "80px" }}>{issue.name} - {issue.symbol}</h3>
                    <button onClick={this.handlePrint}>The Drivers Insight</button>
                    <span className="dateDisplay" style={{ color: "transparent" }}>{new Date().toLocaleDateString()}</span>
                </div>
                <img src="drivers-logo-for-light-bkg.png" alt="Drivers Official Logo" className="headerImage" />

                <div className="printable-section">
                    <div className="descriptionReports" style={{ pageBreakBefore: "always" }}>
                        <p>Drivers continuously optimize our insights based on the latest market data. Recent analysis has identified the last five key inflection points that we are eager to share with you. Please contact us for future inflections.</p>
                        <p>Drivers approach is designed to manage risk effectively, safeguard capital, and minimize portfolio volatility. Our research contrasts with conventional buy-and-hold strategies. It actively adapts to market changes, aims for improved risk-adjusted returns. Here we can see our capital exposure during the past 5 years.</p>
                        <p>Drivers M1 research offers a strategic edge by comparing results with the market. It enables detailed performance evaluations, sharpens strategic adjustments, and enhances competitive positioning. This tool boosts investor confidence, supports active risk management, and improves performance communication while adapting to market shifts. It acts as an essential guide for informed decision-making and ongoing success.</p>
                        <p>Driver research compares market drawdowns to provide insights into risk management, volatility control, and performance expectations. This analysis acts as a stress test, revealing M1 resilience under adverse conditions. It aids in making informed decisions, communicating risks effectively, and maintaining a long-term view on investment strategies. This enhances client understanding and confidence in the model's performance.</p>
                    </div>
                    <div className="sectionFooter">
                        <span className="pageNumber"></span>
                    </div>
                </div>

                <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>

                <div className="printable-section">
                    <div className="sectionHeader">
                        <h4>{issue.name} - {issue.symbol}</h4>
                        <h3>Capital Growth</h3>
                    </div>
                    <div className="CGsection">
                        <div className='CGchartReport chartReport' style={{ position: "relative", padding: "8pt" }}>
                            <CapitalGrowthChart period={period} indicator={indicator} strategy={strategy} chartOptions={CGPrintOptions} />
                        </div>
                        <div className='CGdetailsReport detailsReport'>
                            <CapitalGrowthDetails period={period} indicator={indicator} issue={issue} />
                        </div>
                    </div>
                    <div className="sectionFooter">
                        <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>
                        <span className="dateDisplay">{new Date().toLocaleDateString()}</span>
                        <span className="pageNumber"></span>
                    </div>
                </div>

                <div className="printable-section">
                    <div className="sectionHeader">
                        <h4>{issue.name} - {issue.symbol}</h4>
                        <h3>Capital Exposure</h3>
                    </div>
                    <div className="CEsection">
                        <div className='CEchartReport chartReport' style={{ position: "relative", width: "140mm", padding: "8pt" }}>
                            <CapitalExposureChart period={period} indicator={indicator} />
                        </div>
                        <div className='CEdetailsReport detailsReport'>
                            <CapitalExposureDetails period={period} indicator={indicator} issue={issue} />
                        </div>
                    </div>
                    <div className="sectionFooter">
                        <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>
                        <span className="dateDisplay">{new Date().toLocaleDateString()}</span>
                        <span className="pageNumber"></span>
                    </div>
                </div>

                <div className="printable-section">
                    <div className="sectionHeader">
                        <h4>{issue.name} - {issue.symbol}</h4>
                        <h3>Market Performance</h3>
                    </div>
                    <div className="MPsection">
                        <div className='MPchartReport chartReport' style={{ position: "relative", width: "150mm", padding: "8pt" }}>
                            <MarketPerformanceChart period={period} indicator={indicator} />
                        </div>
                        <div className='MPdetailsReport detailsReport'>
                            <MarketPerformanceDetails period={period} indicator={indicator} issue={issue} />
                        </div>
                    </div>
                    <div className="sectionFooter">
                        <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>
                        <span className="dateDisplay">{new Date().toLocaleDateString()}</span>
                        <span className="pageNumber"></span>
                    </div>
                </div>

                <div className="printable-section">
                    <div className="sectionHeader">
                        <h4>{issue.name} - {issue.symbol}</h4>
                        <h3>Risk Performance</h3>
                    </div>
                    <div className="RPsection" style={{ backgroundColor: "smokewhite" }}>
                        <div className='RPchartReport chartReport' style={{ position: "relative", width: "150mm", padding: "8pt", zIndex: 1 }}>
                            <RiskPerformanceChart period={period} indicator={indicator} />
                        </div>
                        <div className='RPdetailsReport detailsReport' style={{ zIndex: 1 }}>
                            <RiskPerformanceDetails period={period} indicator={indicator} issue={issue} />
                        </div>
                    </div>
                    <div className="sectionFooter">
                        <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>
                        <span className="dateDisplay">{new Date().toLocaleDateString()}</span>
                        <span className="pageNumber"></span>
                    </div>
                </div>

                <div className="printable-section">
                    <div className="sectionHeader">
                        <h4>{issue.name} - {issue.symbol}</h4>
                        <h3>Market Sentiment</h3>
                    </div>
                    <div className="MSsection" style={{ width: "100%" }}>
                        <div className='MSchartReport chartReport' style={{ position: "relative", width: "150mm", padding: "8pt" }}>
                            <MarketSentimentChart period={period} indicators={indicators!} strategy={strategy} />
                        </div>
                        <div className='MSdetailsReport detailsReport' style={{ zIndex: 1 }}>
                            <MarketSentimentDetails />
                        </div>
                    </div>
                    <div className="sectionFooter">
                        <img className='logoSmRep' src='drivers-wave-for-light-bkg.png' style={{ width: "100px", height: "auto" }} alt="Logo"></img>
                        <span className="dateDisplay">{new Date().toLocaleDateString()}</span>
                        <span className="pageNumber"></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrintableDocument;


