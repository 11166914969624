import IssueDaily from "./issueDaily";

interface IIssueHistory {
    readonly symbol: string;
    readonly name: string;
    readonly description?: string;
    readonly refreshDate?: string; // YYYY-MM-DD
    readonly closeTime?: string; // HH:MM
    readonly timeZone?: string; // UTC+/-HH
    readonly daily?: Array<IssueDaily>;
}

/**
 * Provides history data for an issue.
 */
export default class IssueHistory implements IIssueHistory {
    readonly symbol: string;
    readonly name: string;
    readonly description?: string;
    readonly refreshDate?: string; // YYYY-MM-DD
    readonly closeTime?: string; // HH:MM
    readonly timeZone?: string; // UTC+/-HH
    readonly daily?: Array<IssueDaily>;

    constructor(source: IIssueHistory | string, refreshDate?: string, daily?: Array<IssueDaily>, name?: string) {
        if (typeof source === "string") {
            // Source is symbol
            this.symbol = source;
            this.name = name ?? "Placeholder for " + this.symbol;
            this.refreshDate = refreshDate;
            this.daily = daily;
            return;
        }

        // Assume source is the interface,
        // treat as a copy constructor
        this.symbol = source.symbol;
        this.name = source.name;
        this.description = source.description;
        this.refreshDate = refreshDate ?? source.refreshDate;
        this.closeTime = source.closeTime;
        this.timeZone = source.timeZone;
        this.daily = daily ?? source.daily;
    }

    /** @deprecated */
    get closeData() {
         return this.daily?.map(day => day.close);
    }
}
