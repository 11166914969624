import React from 'react';
import ModelIndicator from '../../../services/ModelIndicator';
import ChromeService from '../../../services/chromeService';
import { Doughnut } from 'react-chartjs-2';
import { IndicatorPeriod } from '../mahutDemo.types';
import ModelReportBuilder from '../../../services/ModelReportBuilder';


/** Specifies the capital exposure chart's properties. */
type CapitalExposureChartProps = {
  period: IndicatorPeriod;
  indicator: ModelIndicator;
}


/**
 * Displays the capital exposure chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function CapitalExposureChart(props: CapitalExposureChartProps): React.ReactElement {
  const chartData = React.useMemo(() => {
    // Generate report
    const agoraReport = ModelReportBuilder.build(props.indicator, props.period);
    const report = props.indicator.getInflectionReport(agoraReport.startDate, agoraReport.endDate);

    // Determine the chart properties
    const data = {
      labels: ["Open Position", "Closed Position"],
      datasets:[{
        label: "Exposure",
        data: [report.days, Math.max(report.totalDays - report.days, 0)],
        backgroundColor: [
          ChromeService.graphColor.positive,
          ChromeService.graphColor.neutral,
        ]
      }],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    return {data, options};
  }, [props.indicator, props.period]);
  return (
    <div style={{position: "absolute", inset: "4pt", display: "flex", flexDirection: "column", alignItems: "center"}}>
      <Doughnut data={chartData.data} options={chartData.options} />
    </div>
  );
}
