import React from 'react';
import "./pageTabBar.css";

export type PageTabBarTab = {
  tabId: string;
  display: string;
}

type PageTabBarProps = {
  tabs: PageTabBarTab[];
  currentTab: PageTabBarTab;
  onSelect: (tab: PageTabBarTab) => void;
}

export default function PageTabBar(props: PageTabBarProps): React.ReactElement {
  return (
    <div className="mahut-demo-tab-bar">
    {
      props.tabs.map(tab =>
        <PageTab
          key={tab.tabId}
          tab={tab}
          isSelected={tab.tabId === props.currentTab.tabId}
          onSelect={(tabId) => {
            props.onSelect(tab);
          }}
        />
      )
    }
    </div>
  );
}

type PageTabProps = {
  tab: PageTabBarTab;
  isSelected: boolean;
  onSelect: (tabId: string) => void;
};

function PageTab(props: PageTabProps): React.ReactElement {
  const pageTabStyle = `page-tab${props.isSelected ? " page-tab-selected" : ""}`
  return (
    <div className={pageTabStyle} onClick={() => props.onSelect(props.tab.tabId)}>
    { props.tab.display }
    </div>
  )
}