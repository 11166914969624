import React from 'react';

import "./descriptionPanel.css";

import { IndicatorStrategy } from '../../entities/indicatorStrategy';
import { IndicatorPeriod, IndicatorPeriods } from './mahutDemo.types';
import IssueHistory from '../../entities/issueHistory';
import { PageTabBarTab } from './pageTabBar';

type DescriptionPanelProps = {
  tab: PageTabBarTab;
  strategy: IndicatorStrategy;
  period: IndicatorPeriod;
  issue?: IssueHistory
  onChange: (strategy: IndicatorStrategy, period: IndicatorPeriod) => void;
};


/**
 * Provides the description panel at the bottom of the content area.
 * This contains controls to display and select active vs passive
 * strategy, and the display period, in addition to the description
 * text.
 * 
 * TODO: Once we're sure we no longer need the button controls here,
 *   remove them from the code and styling.
 * 
 * @returns Panel element tree
 */
export default function DescriptionPanel(props: DescriptionPanelProps): React.ReactElement | null {
  // Selecting state
  const [isSelectingPeriod, setSelectingPeriod] = React.useState(false);

  // Rendering helpers
  const strategyClass = (strategy: IndicatorStrategy) =>
    "descr-controls-button " + 
    (strategy === props.strategy ? "strategy-selected" : "strategy-unselected");
  const onSelectStrategy = (strategy: IndicatorStrategy) =>
    props.onChange(strategy, props.period);
  const selectPeriodClass = `descr-select-period${isSelectingPeriod ? " select-showing" : ""}`;
  const noIssueClass = !props.issue ? " no-issue" : "";
  const textDescription = React.useMemo<string>(() => {
    return generateDescriptionText(props);
  }, [props]);

  // Returns the description panel elements
  return (
    <div className={"mahut-demo-descr" + noIssueClass}>
      <div className="descr-controls" style={{ display: "none"}}> { /* TODO */ }
        <div
          className={strategyClass(IndicatorStrategy.ThreeDay)}
          onClick={() => onSelectStrategy(IndicatorStrategy.ThreeDay)}
        >
          Active
          <div className="strategy-mark"><div /></div>
        </div>
        <div className={strategyClass(IndicatorStrategy.Weekly)}
          onClick={() => onSelectStrategy(IndicatorStrategy.Weekly)}
        >
          Passive
          <div className="strategy-mark"><div /></div>
        </div>
        <div
          className="descr-controls-button"
          onClick={() => setSelectingPeriod(!isSelectingPeriod)}
        >
          { props.period.description }
        </div>
      </div>
      <div className="descr-description">
        { textDescription }
        <div className={selectPeriodClass}>
          <div />
          <div>
            <div>
              {
                IndicatorPeriods
                  .filter(period => period !== props.period)
                  .reverse().map(period =>
                    <div
                      key={period.months}
                      className="descr-period-button"
                      onClick={() => {
                        setSelectingPeriod(false);
                        props.onChange(props.strategy, period);
                      }}
                    >
                      { period.description }
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


/** Produces a description based on the current display properties. */
function generateDescriptionText(props: DescriptionPanelProps): string {
  switch (!!props.issue && props.tab.tabId) {
    case "capgro":
      return "";
    case "capexp":
      return `
      Rotating positions from asset exposure to cash, based on time spent in the market, 
      offers a dynamic and adaptable strategy for managing risk. This approach aims to preserve capital,
      reduce portfolio volatility, and seize opportunities during favorable market conditions.
      It differs from a traditional buy-and-hold strategy by actively responding to market dynamics,
      seeking improved risk-adjusted returns, and providing clients with a more flexible and resilient approach.
      `;
    case "marprf":
      return `
      Comparing your financial performance to market benchmarks through a comprehensive model provides 
      a strategic advantage. This tool allows for a thorough evaluation of results, identifies areas
      for strategic enhancement, and aids in effective capital positioning. It builds investor confidence,
      supports proactive risk management, improves performance communication, and fosters adaptability to
      changing market conditions. Serving as a guiding compass for informed decision-making, this model
      is crucial for achieving and sustaining long-term business success.
      `;
    case "rskprf":
      return `
      Comparing drawdowns in the market to the model's performance provides clients with valuable insights
      into risk management, volatility, and expectations from the investment strategy. This comparison acts 
      as a stress test, offering transparency on how well the model withstands challenging market conditions. 
      Analyzing the model's behavior during market downturns helps clients make informed decisions, communicate 
      risks effectively, and maintain a long-term perspective on their investment strategies. This process enhances 
      overall client understanding and confidence in the model's performance, even in turbulent markets.
      `;
    case "marsen":
      return `
      Introducing clients to a financial model incorporating a heat map brings numerous advantages. 
      The visual representation simplifies complex data, facilitating analysis and strategic decision-making. 
      This tool serves as a communication aid, enabling discussions and offering insights into risk mitigation, 
      diversification, and scenario planning. By transforming intricate financial information into a clear narrative, 
      the heat map empowers clients to navigate their financial landscape confidently.
      `;
  }

  // No specific description
  return "";
}