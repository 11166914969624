import React from 'react';
import { toast } from 'react-toastify';

import "./mahutDemo.css"

import DescriptionPanel from './descriptionPanel';
import LookupSymbol from '../lookupSymbol';
import IssueHistoryLoader from '../../services/issueHistoryLoader';
import IssueHistory from '../../entities/issueHistory';
import { IndicatorStrategy } from '../../entities/indicatorStrategy';
import { ChartOption, ChartTabs, IndicatorPeriod, IndicatorPeriods } from './mahutDemo.types';
import PageTabBar, { PageTabBarTab } from './pageTabBar';
import ChartPanel, { getChartOptionsFor, getDefaultChartOptions } from './charts/chartPanel';
import ModelIndicator from '../../services/ModelIndicator';
import DetailPanel from './details/detailPanel';
import OptionPicker, { OptionsActive } from './optionPicker/optionPicker';
import { getDefaultCapitalGrowthOptions } from './charts/capitalGrowthChart';
import LandingPanel from './landingPanel'; // Import LandingPanel
import { ModelIndicatorBuilder } from '../../services/ModelIndicatorBuilder';

// TODO Cleanup needed, maybe move to someplace more global, and/or unhook when we're done
// TODO We could also consider making the web view fixed size to match the print needs,
//    although we might still need this to force resize on print view anyway.
// TODO Could also consider using react-to-print
// Might all be obsolete if/when we move this feature to the cloud and use a PDF generator.
import { Chart } from 'chart.js';
function printHandler (ev: Event, before: boolean) {
  for (let id in Chart.instances) {
    const chart = Chart.instances[id];
    const chartParent = chart.canvas.parentElement;
    if (chartParent) {
      if (before) {
        chartParent.style.width = "160mm";
        const printWidth = chartParent.offsetWidth;
        const printHeight = chartParent.offsetHeight;
        chart.resize(printWidth, printHeight);
      } else {
        chartParent.style.width = "";
        chart.resize();
      }
    }
  }
}
window.addEventListener('beforeprint', (ev) => printHandler(ev, true));
window.addEventListener('afterprint', (ev) => printHandler(ev, false));

/**
 * Implements Mahout demo screen
 * @returns Mahout demo DOM
 */
export default function MahutDemo(): React.ReactElement {
  const [strategy, setStrategy] = React.useState<IndicatorStrategy>(IndicatorStrategy.ThreeDay);
  const [period, setPeriod] = React.useState<IndicatorPeriod>(IndicatorPeriods[0]);
  const [selectedTab, setSelectedTab] = React.useState(ChartTabs[0]);
  const [displayIssue, setDisplayIssue] = React.useState<IssueHistory | null>(null);
  const [indicators, setIndicators] = React.useState<Array<ModelIndicator>>();
  const [optionActive, setOptionActive] = React.useState(OptionsActive.none);
  const [chartOptions, setChartOptions] = React.useState<ChartOption[]>(getDefaultChartOptions());

  const showChartOptions = React.useMemo<ChartOption[]>(() => {
    return getChartOptionsFor(selectedTab, chartOptions);
  }, [selectedTab, chartOptions]);

  const handleSymbolLookup = (issue: IssueHistory, indicators: ModelIndicator[]): void => {
    setDisplayIssue(issue);
    setIndicators(indicators);
    updateSelectedTab(ChartTabs[0]);
    updateChartOptions(getDefaultCapitalGrowthOptions());
  };

  const lookupSymbol = (symbol: string): void => {
    const toastId = toast.loading("Looking up issue...");
    IssueHistoryLoader.loadIssue(symbol)
      .then(loadResult => {
        if (typeof loadResult === "string") {
          toast.update(toastId, {
            render: loadResult,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else if ((loadResult.daily?.length ?? 0) < IndicatorPeriod.MIN_TRADING_DATES) {
          // Too few trading days, reject
          const msg = `${loadResult.symbol} has too few trading days for reporting.`;
          toast.update(toastId, {
            render: msg,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          const loadedIssue: IssueHistory = loadResult;
          const daily = loadedIssue.daily!;
          toast.dismiss(toastId);
          setDisplayIssue(loadedIssue);
          setIndicators([
            new ModelIndicatorBuilder(daily, IndicatorStrategy.Daily).build(),
            new ModelIndicatorBuilder(daily, IndicatorStrategy.ThreeDay).build(),
            new ModelIndicatorBuilder(daily, IndicatorStrategy.Weekly).build(),
            new ModelIndicatorBuilder(daily, IndicatorStrategy.Monthly).build(),
            new ModelIndicatorBuilder(daily, IndicatorStrategy.ThreeMonth).build(),
          ]);
          updateSelectedTab(ChartTabs[0]);
          updateChartOptions(getDefaultCapitalGrowthOptions());
        }
      });
  };

  const updateSelectedTab = (tab: PageTabBarTab) => {
    setSelectedTab(tab);
    setOptionActive(OptionsActive.none);
  };

  const updateChartOptions = (updates: ChartOption[]) => {
    const finalOptions = chartOptions.map(orgOpt => {
      const updOpt = updates.find(findOpt => findOpt.id === orgOpt.id);
      return updOpt ?? orgOpt;
    });
    setChartOptions(finalOptions);
  };

  const showDetails = selectedTab.tabId !== "print";
  const showDescription = showDetails &&
    (chartOptions.find(opt => opt.id === "comments")?.isEnabled ?? true);

  return (
    <div style={{
      position: "absolute", top: "0", left: "0", bottom: "0", right: "0",
      display: "flex", flexDirection: "column", padding: "5pt",
    }}>
      {displayIssue ? (
        <>
          <div className="mahut-demo-head" style={{ gap: 0 }}>
            <div>
              <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <LookupSymbol onSymbol={lookupSymbol} />
              </div>
              <div>
                <PageTabBar
                  tabs={ChartTabs}
                  currentTab={selectedTab}
                  onSelect={updateSelectedTab}
                />
              </div>
            </div>
            <div className='random-div' style={{ width: "12pt", borderBottom: "1px solid" }} />
            <OptionPicker
              tab={selectedTab}
              strategy={strategy}
              period={period}
              chartOptions={showChartOptions}
              optionsActive={optionActive}
              setOptionActive={setOptionActive}
              setStrategy={setStrategy}
              setPeriod={setPeriod}
              setChartOption={updateChartOptions}
            />
          </div>

          <div className="mahut-demo-cont">
            <div className="cont-main">
              <div className="cont-graph">
                <ChartPanel
                  tab={selectedTab}
                  period={period}
                  displayIssue={displayIssue}
                  strategy={strategy}
                  indicators={indicators}
                  chartOptions={showChartOptions}
                  setChartOption={updateChartOptions}
                />
              </div>
              {showDescription && (
                <div className="cont-descrip">
                  <DescriptionPanel
                    tab={selectedTab}
                    strategy={strategy}
                    period={period}
                    issue={displayIssue}
                    onChange={(strategy, period) => {
                      setStrategy(strategy);
                      setPeriod(period);
                    }}
                  />
                </div>
              )}
            </div>
            { showDetails &&
            <div className="cont-detail">
              <DetailPanel
                tab={selectedTab}
                issue={displayIssue}
                strategy={strategy}
                indicators={indicators}
                period={period}
              />
            </div>
            }
          </div>

          <div className="mahut-demo-foot">
            <div>PROPRIETARY & CONFIDENTIAL, UNAUTHORIZED ACCESS PROHIBITED</div>
            <div className="foot-right">Copyright {new Date().getFullYear()} Drivers Limited</div>
          </div>
        </>
      ) : (
        <LandingPanel onSymbolLookup={handleSymbolLookup} />
      )}
    </div>
  );
}
