import React from "react";
import { IndicatorStrategy } from "../../../entities/indicatorStrategy";
import { ChartOption, IndicatorPeriod, IndicatorPeriods } from "../mahutDemo.types";
import { PageTabBarTab } from "../pageTabBar";
import "./optionPicker.css";
import { LoginService } from "../../../services/loginService";

export enum OptionsActive {
  none,
  strategy,
  period,
  details,
};

type OptionPickerProps = {
  tab: PageTabBarTab;
  strategy: IndicatorStrategy;
  period: IndicatorPeriod;
  chartOptions: ChartOption[];
  optionsActive: OptionsActive;
  setOptionActive: (setOption: OptionsActive) => void;
  setStrategy: (selected: IndicatorStrategy) => void;
  setPeriod: (selected: IndicatorPeriod) => void;
  setChartOption: (options: ChartOption[]) => void;
}

/**
 * Presents the option picker component
 * @param props Properties from parent
 * @returns Component root element
 */
export default function OptionPicker(props: OptionPickerProps): React.ReactElement {

  /** Handles click on option button. */
  const onOptionButton = (button: OptionsActive) => {
    if (button === props.optionsActive) {
      props.setOptionActive(OptionsActive.none);
    } else {
      props.setOptionActive(button);
    }
  }

  /** Renders a specific option button. */
  const renderOptionButton = (button: OptionsActive): React.ReactElement => {
    let label = "Error";
    const className = `option-button option-category${button === props.optionsActive ? " option-active" : ""}`;
    if (button === OptionsActive.period) {
      label = props.period.description;
    } else if (button === OptionsActive.strategy) {
      label = props.strategy;
    } else if (button === OptionsActive.details) {
      label = props.chartOptions.find(opt => opt.radioGroup === "cpdisplay" && opt.isEnabled)?.name ?? "Details";
    }
    return (
      <div className={className} onClick={() => onOptionButton(button)}>
        <div>{label}</div><div className="strategy-mark"><div /></div>
      </div>
    );
  }

  /** Handles selection of new strategy. */
  const onStrategy = async (selected: IndicatorStrategy) => {
    props.setStrategy(selected);
    props.setOptionActive(OptionsActive.none);
  }

  /** Renders the strategy options. */
  const renderStrategyOptions = (): React.ReactNode => {
    const hasM0Feature = LoginService.getCurrentUser()?.features.some(feature => feature === LoginService.FEATURE_MO);
    const allStrategies: IndicatorStrategy[] = [
      IndicatorStrategy.Daily,
      IndicatorStrategy.ThreeDay,
      IndicatorStrategy.Weekly
    ];
    const strategies = allStrategies.filter(strategy =>
      strategy !== props.strategy && (strategy !== IndicatorStrategy.Daily || hasM0Feature)
    );

    return props.optionsActive === OptionsActive.strategy && (
      <div className="options-menu">
        { strategies.map(strategy => (
          <div key={strategy} className="option-button" onClick={() => onStrategy(strategy)}>
            <div>{strategy}</div>
          </div>
        ))}
      </div>
    );
  }

  /** Handles the selection of a new period. */
  const onPeriod = (selected: IndicatorPeriod) => {
    props.setPeriod(selected);
    props.setOptionActive(OptionsActive.none);
  }

  /** Renders the period options. */
  const renderPeriodOptions = (): React.ReactNode => {
    if (props.optionsActive !== OptionsActive.period) {
      return false;
    }
    const showPeriods = IndicatorPeriods.filter(current => current !== props.period).reverse();
    return (
      <div className="options-menu">
        {
          showPeriods.map(period => (
            <div key={period.months} className="option-button" onClick={() => onPeriod(period)}>
              <div>{period.description}</div>
            </div>
          ))
        }
      </div>
    );
  }

  /** Handles click on detail button. */
  const onToggleDetail = (option: ChartOption) => {
    const updateOptions: ChartOption[] = [];
    if (!!option.radioGroup) {
      // Special handling for radio group buttons
      if (option.isEnabled) {
        // This is not actually a toggle, just close
        props.setOptionActive(OptionsActive.none);
        return;
      }

      // Make sure other options in group are off
      props.chartOptions.forEach(testOpt => {
        if ((testOpt.radioGroup === option.radioGroup) && (testOpt.id !== option.id) && testOpt.isEnabled) {
          updateOptions.push({ ...testOpt, isEnabled: false });
        }
      });
    }

    // Toggle option
    updateOptions.push({ ...option, isEnabled: !option.isEnabled });
    props.setChartOption(updateOptions);
    props.setOptionActive(OptionsActive.none);
  }

  /** Renders the chart detail options */
  const renderChartDetailOptions = (): React.ReactNode => {
    if (props.optionsActive !== OptionsActive.details) {
      return false;
    }
    const addtlStyl: React.CSSProperties = {};
    const extraSpace = 3 - props.chartOptions.length;
    if (extraSpace > 0) {
      addtlStyl.borderTop = "1px solid";
      addtlStyl.transform = `translatey(calc(-1px + var(--options-button-height) * ${extraSpace}))`;
    }

    return (
      <div className="options-menu" style={addtlStyl}>
        {
          props.chartOptions.map(option => {
            const key = `${option.chart}~${option.name}`;
            return (
              <div key={key} className="option-button option-check" onClick={() => onToggleDetail(option)}>
                <span className="material-icons">{option.isEnabled ? "check_box" : "check_box_outline_blank"}</span>
                {option.name}
              </div>
            );
          })
        }
      </div>
    );
  }

  /* Renders the options component. */
  return (
    <div className="mahut-option-picker">
      <div className="menu-host">
        {/* Logo/options */}
        <div className="logo-title">
          <div className="menu-logo" />
          <div style={{ display: "none", flex: "1 1 0", backgroundImage: "url(drivers-logo-for-light-bkg.png)", backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />
        </div>
        {/* Selected Options */}
        {renderStrategyOptions()}
        {renderPeriodOptions()}
        {renderChartDetailOptions()}
      </div>
      <div className="current-options">
        {/* Option categories */}
        {renderOptionButton(OptionsActive.strategy)}
        {renderOptionButton(OptionsActive.period)}
        {renderOptionButton(OptionsActive.details)}
      </div>
    </div>
  );
};
