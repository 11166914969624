import IssueList from "../../entities/issueList";
import SymbolSearch, { SymbolSearchResult } from "../../entities/symbolSearch"
import IIssueParser from "./issueParser";

/** Provides result of evaluation symbol selection. */
export interface SelectResult {
  symbol: string; /**< Normalized symbol */
  isComplete: boolean; /**< Is complete flag */
  isValid: boolean; /**< Is valid flag */

  name?: string; /**< Name of issue if #isValid */
  mainSymbol?: string; /**< Main symbol if #isValid */
  pairSymbol?: string; /**< Pair symbol if #isValid and the issue is paired */
}


/**
 * Parses internally managed symbols.
 */
export default class IssueSymbolParser implements IIssueParser {

  /** Name of this type for smart search. */
  private readonly typeName: string;

  /** Prefix identifies symbols that we parse */
  private readonly symbolPrefix: string;

  /** Provides the main issue to parse. */
  private readonly mainIssues: IssueList;

  /** Provides the optional pair issue to parse. */
  private readonly pairIssues?: IssueList;

  /** Separates symbol segments. */
  private readonly segmentSep = ".";

  /** Prefix formatted as initial segment */
  private readonly prefixSegment: string;

  /** Generic results with the type prefix. */
  private readonly genericResult: SymbolSearchResult;

  /** Symbols for common main issues picked out of the list. */
  private readonly commonMain: string[];

  /** Symbols for common pair issues picked out of the list. */
  private readonly commonPair?: string[];

  /**
   * Creates a new parser.
   * @param typeName Issue type name
   * @param symbolPrefix Prefix identifies our symbols
   * @param mainIssues Main issue to parse
   * @param pairIssues Optional pair issue to parse
   */
  constructor(typeName: string, symbolPrefix: string, mainIssues: IssueList, pairIssues?: IssueList) {
    // Retain instance values
    this.typeName = typeName;
    this.symbolPrefix = symbolPrefix.toUpperCase();
    this.mainIssues = mainIssues;
    this.pairIssues = pairIssues;

    // Initialize derived values
    this.prefixSegment = `${this.symbolPrefix}${this.segmentSep}`;
    this.genericResult = { symbol: this.symbolPrefix, name: this.typeName };
    this.commonMain = IssueSymbolParser.getCommonIssues(this.mainIssues);
    this.commonPair = (this.mainIssues === this.pairIssues)
      ? this.commonMain
      : (this.pairIssues && IssueSymbolParser.getCommonIssues(this.pairIssues)) ?? undefined;
  }

  /**
   * Generates list of common issue symbols
   * @param issues Issues list
   * @returns Common issues
   */
  static getCommonIssues(issues: IssueList): string[] {
    return Object.keys(issues)
      .filter((symbol, _, array) => !!issues![symbol].commonOrder)
      .sort((syma, symb) => {
        let issa = issues[syma];
        let issb = issues[symb];
        let res = issa.commonOrder! - issb.commonOrder!;
        return !!res ? res : issa.name.localeCompare(issb.name);
      });
  }

  /**
   * Indicates if the selected symbol is our type, complete and valid.
   * @param symbol Test symbol
   * @returns State of this symbol
   */
  select(symbol: string): SelectResult | false {
    const normSymbol = symbol.toUpperCase();
    if (normSymbol === this.symbolPrefix) {
      // Return prefix segment
      return {symbol: this.prefixSegment, isComplete: false, isValid: false };
    }
    const segments = symbol.split(this.segmentSep);
    if (segments[0] !== this.symbolPrefix) {
      // We don't parse this symbol
      return false;
    }
    if (segments.length === 2) {
      // Is this type a pair?
      if (!this.pairIssues) {
        // No, complete single symbol
        const isValid = this.isValidIssue(segments[1]);
        const name = this.mainIssues[segments[1]]?.name;
        return { symbol: normSymbol, isComplete: true, isValid, name, mainSymbol: segments[1] };
      }

      // Incomplete pair
      return { symbol: `${normSymbol}.`, isComplete: false, isValid: false };
    }
    if ((segments.length === 3) && !!this.pairIssues) {
      // This type is a pair
      const isValid = this.isValidIssue(segments[1], segments[2]);
      const mainName = this.mainIssues[segments[1]].name;
      const pairName = this.pairIssues[segments[2]].name;
      const name = `${this.typeName} ${mainName} to ${pairName}`;
      return { symbol: normSymbol, isComplete: true, isValid, name, mainSymbol: segments[1], pairSymbol: segments[2], };
    }

    // Too many segments
    return { symbol: normSymbol, isComplete: true, isValid: false };
  }

  /**
   * Validates issues.
   * @param mainIssue Optional main issue to validate
   * @param pairIssue Optional pair issue to validate
   */
  isValidIssue(mainIssue?: string, pairIssue?: string): boolean {
    if (!!mainIssue && !Object.hasOwn(this.mainIssues, mainIssue)) {
      return false;
    }
    if (!!pairIssue) {
      const res = !!this.pairIssues && Object.hasOwn(this.pairIssues, pairIssue);
      return res && ((this.mainIssues !== this.pairIssues) || (mainIssue !== pairIssue));
    }
    return true;
  }

  /**
   * Perform search for symbols of our type.
   * @param searchText User search text
   * @returns Search results or false for not type
   */
  search(searchText: string): SymbolSearch | false {
    // Normalize text
    const normText = searchText.toUpperCase();
    if (!normText.startsWith(this.prefixSegment)) {
      // Are there any hints that the user wants this symbol type?
      const normName = this.typeName.toUpperCase();
      const isHint = normText.split(" ").reduce((res, token) => 
        res || (token === this.symbolPrefix) || ((token.length > 2) && normName.startsWith(token))
      , false);
      if (isHint) {
        return { searchText, results: [this.genericResult] };
      }
      return false;
    }

    // Prefix token selected, look for symbols
    const symbols = normText.split(this.segmentSep);
    if (symbols.length === 2) {
      // Search for first symbol
      return this.searchForSymbol(searchText, this.prefixSegment, this.typeName, symbols[1]);
    }
    if (symbols.length > 3) {
      // Too many separators
      return false;
    }
    if (!this.pairIssues && symbols.length > 2) {
      // No pair symbol, too many parameters
      return false;
    }

    // Make sure first symbol is valid
    if (!this.isValidIssue(symbols[1])) {
      return { searchText, results: [this.genericResult] };
    }

    // Search for second symbol
    const firstName = this.mainIssues[symbols[1]].name;
    return this.searchForSymbol(searchText, `${this.prefixSegment}${symbols[1]}.`, `${this.typeName} ${firstName}`, symbols[2], symbols[1]);
  }

  /**
   * Search results for a symbol in an or type issues.
   * @param searchText Full search text
   * @param symbolPrefix Results symbol prefix
   * @param namePrefix Results name prefix
   * @param searchSymbol Partial or full symbol
   * @param excludeSymbol Exclude first symbol in pair
   * @returns Search results
   */
  private searchForSymbol(searchText: string, symbolPrefix: string, namePrefix: string, searchSymbol: string, excludeSymbol?: string): SymbolSearch | false {
    const res: SymbolSearch = { searchText, exactMatch: true, results: [] };
    const isPair = !!excludeSymbol;
    const issues = isPair && this.pairIssues ? this.pairIssues : this.mainIssues;
    if (isPair && (this.mainIssues !== this.pairIssues)) {
      excludeSymbol = undefined;
    }
    if (searchSymbol === "") {
      // Show common currencies
      const common = isPair && this.commonPair ? this.commonPair : this.commonMain;
      common.forEach(symbol => {
         if (symbol !== excludeSymbol) {
            const name = `
               ${namePrefix}
               ${isPair ? "to" : ""}
               ${issues[symbol].name}`;
            res.results.push({
               symbol: `${symbolPrefix}${symbol}`,
               name,
            });
         }
      });
    } else if (excludeSymbol !== searchSymbol) {
      // Show search results
      Object.keys(issues).forEach(symbol => {
        if (symbol === excludeSymbol) {
          // Ignore this symbol
          return;
        }
        const symbolName = issues[symbol]?.name ?? symbol;
        const separator = isPair ? " to " : " ";
        if (symbol.startsWith(searchSymbol)) {
          // Symbol match
          if (symbol === searchSymbol) {
            res.results = [{symbol: `${symbolPrefix}${symbol}`, name: `${namePrefix}${separator}${symbolName}`}];
            res.exactMatch = true;
          } else {
            res.results.push({symbol: `${symbolPrefix}${symbol}`, name: `${namePrefix}${separator}${symbolName}`});
          }
        } else if ((searchSymbol.length > 3) && symbolName.toUpperCase().includes(searchSymbol)) {
          res.results.push({symbol: `${symbolPrefix}${symbol}`, name: `${namePrefix}${separator}${symbolName}`});
        }
      });
    }

    // Final processing
    if (res.results.length === 0) {
      // Return generic result instead of empty
      res.results.push(this.genericResult);
    } else {
      // Return max 5 results
      res.results = res.results.slice(0, 5);
    }

    // Return search results
    return res;
  }
}
