import React, { Fragment } from 'react';
import MahutDemo from './components/mahutDemo';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './chartSetup';

/**
 * Hosts the React SPA.
 * @returns SPA root node
 */
function App() {
  return (
    <Fragment>
      <MahutDemo />
      <ToastContainer />
    </Fragment>
  );
}

export default App;
