import { Inflection } from "../entities/inflectionPoint";

interface PeriodSummary {
  label: string;
  marketPct: number;
  modelPct: number;
  marketRisk: number;
  modelRisk: number;
}

/**
 * Represents a report generated from analyzing financial data using a model.
 *
 * @see ModelReportBuilder
 */
export default class ModelReport {
  readonly startDate!: Date;
  readonly endDate!: Date;
  readonly groupByMonths!: number;
  readonly inflections!: Inflection.Point[];
  readonly summary!: PeriodSummary;
  readonly summaryBreakdown!: PeriodSummary[];
  readonly capitalGrowthMarket!: number[];
  readonly capitalGrowthModel!: number[];
  readonly cagrMarket!: number;
  readonly cagrModel!: number;
  readonly marketDriversScore!: number;
  readonly modelDriversScore!: number | null;
  readonly marketSharpeRatio!: number;
  readonly modelSharpeRatio!: number;

  constructor() {}

  public get capitalGrowthMarketValue(): number | undefined {
    return this.capitalGrowthMarket.at(-1);
  }

  public get capitalGrowthModelValue(): number | undefined {
    return this.capitalGrowthModel.at(-1);
  }

  // Converts a ModelReport instance to a JSON string.
  public static toJson(modelReport: ModelReport): string {
    return JSON.stringify(modelReport);
  }

  // Creates a new ModelReport instance from a JSON string.
  public static fromJson(json: string): ModelReport {
    const data = JSON.parse(json);
    const modelReport = new ModelReport();
    Object.assign(modelReport, data);
    return modelReport;
  }
}
