import React, { useMemo } from 'react';
import ModelIndicator from '../../../services/ModelIndicator';
import ModelReportBuilder from '../../../services/ModelReportBuilder';
import ChromeService from '../../../services/chromeService';
import { Line } from 'react-chartjs-2';
import { ChartOption, IndicatorPeriod } from '../mahutDemo.types';
import { IndicatorStrategy } from '../../../entities/indicatorStrategy';

type CapitalGrowthChartProps = {
  period: IndicatorPeriod;
  strategy: IndicatorStrategy;
  indicator: ModelIndicator;
  chartOptions?: ChartOption[];
  setChartOption?: (options: ChartOption[]) => void;
}

export default function CapitalGrowthChart(props: CapitalGrowthChartProps): React.ReactElement {
  const { marketLine, modelLine, indBuy, labels } = useMemo(() => {
    // Calculate capital growth
    const report = ModelReportBuilder.build(props.indicator, props.period);

    // Calculate normalized indicator display
    const { startIdx, endIdx }  = props.indicator.indexRange(report.startDate, report.endDate);
    const indBuy = props.indicator.buyScaled(startIdx, endIdx);
    const labels = indBuy.map(() => '');

    // Memo chart data
    return {
      marketLine: report.capitalGrowthMarket,
      modelLine: report.capitalGrowthModel,
      indBuy,
      labels,
    };
  }, [props.indicator, props.period]);

  // Determine display options
  const showModel = !!props.chartOptions?.find(opt => opt.isEnabled && ["cpmodel", "cpboth"].includes(opt.id));
  const showGrowth = !!props.chartOptions?.find(opt => opt.isEnabled && ["cpprice", "cpboth"].includes(opt.id));

  // Prepare overlapping segments
  const overlappingLine = marketLine.map((value, index) => (value === modelLine[index] ? value : null));

  // Find global max/min to sync y-axis
  const growthChartData = {
    labels: Array(marketLine.length).fill(''),
    datasets: [
      {
        label: 'Model = Market',
        data: overlappingLine,
        borderColor: ChromeService.graphColor.neutral, 
        borderWidth: 2,
        pointRadius: 0,
        spanGaps: true,
      },
      {
        label: 'Market Growth',
        data: marketLine,
        borderColor: ChromeService.graphColor.marketPositive,
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        label: 'Model Growth',
        data: modelLine,
        borderColor: ChromeService.graphColor.positive,
        borderWidth: 2,
        pointRadius: 0,
      },
  
    ]
  };

  const growthChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: {
        grid: { display: false },
        ticks: {
          //formatter for the ticks on y axis
          callback: function (value: any, index: any, values: any) {
            if (value >= 1000000) return '$' + (value / 1000000).toFixed(1) + 'M'; //conversion 'M' for millions
            if (value >= 1000) return '$' + (value / 1000).toFixed(1) + 'k'; // coversion for 'k; for thousands
            return value;
          }
        }
      }
    },
    plugins: {
      legend: { display: true }
    }
  };

  const modelChartData: any = showModel && {
    labels,
    datasets: [{
      label: "Buy Indicator",
      data: indBuy,
      segment: {
        borderColor: (ctx: any) => {
          const idx = ctx.p1DataIndex;
          const ind = indBuy[idx];
          return ind > 0
            ? ChromeService.graphColor.positive
            : ChromeService.graphColor.negative
        }
      }
    }]
  }

  const modelChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 0,
    borderWidth: 1,
    scales: {
      x: { display: false },
      y: {
        grid: {
          display: false,
          color: 'transparent',
        },
        ticks: {
          color: "transparent", // Take up space but don't show
          callback: function (value: any) {
            return '///' + value.toFixed(1); // to push axis out (there must be a better way to do this)
          }
        }
      }
    },
    plugins: {
      legend: { display: false }
    }
  };

  // Render the chart based on selected options
  return (
    <div style={{ position: "absolute", inset: 0, padding: "4pt" }}>
      {showGrowth && (
        <div style={{ height: showModel ? "66%" : "100%" }}>
          <Line data={growthChartData} options={growthChartOptions} />
        </div>
      )}
      {showModel && (
        <div style={{ height: showGrowth ? "34%" : "100%" }}>
          <Line data={modelChartData} options={modelChartOptions} />
        </div>
      )}
    </div>
  );
}

const growthOption: ChartOption = { chart: "capgro", id: "cpprice", name: "Growth", isEnabled: true, radioGroup: "cpdisplay" };
const modelOption: ChartOption = { chart: "capgro", id: "cpmodel", name: "Model", isEnabled: false, radioGroup: "cpdisplay" };
const bothOption: ChartOption = { chart: "capgro", id: "cpboth", name: "Strength", isEnabled: false, radioGroup: "cpdisplay" };
export const printOptions: ChartOption[] = [growthOption];

export function getDefaultCapitalGrowthOptions(): ChartOption[] {
  return [growthOption, modelOption, bothOption];
}
