import IssueList from "../../entities/issueList";
import IssueSymbolParser from "./issueSymbolParser";
/**
 * Database of commodity issues.
 */
const commodityIssues: IssueList = {
  "WTI": { name: "Crude Oil West Texas Intermediate (WTI)" },
  "BRT": { name: "Crude Oil Brent", commonOrder: 1 },
  "GAS": { name: "Natural Gas", commonOrder: 2 },
  "CU": { name: "Copper", commonOrder: 5 },
  "AL": { name: "Aluminum" },
  "WHT": { name: "Wheat", commonOrder: 3 },
  "CRN": { name: "Corn" },
  "CTN": { name: "Cotton" },
  "SGR": { name: "Sugar", commonOrder: 4 },
  "CFE": { name: "Coffee" },
  "GCI": { name: "Global Commodities Index" },
};

/** Parses symbols for commodity issues. */
const commodityParser = new IssueSymbolParser("Commodity", "CY", commodityIssues);

export { commodityIssues, commodityParser };