import React from "react";
import "./lookupSymbol.css"
import SymbolSearch from "../../entities/symbolSearch";
import IssueHistoryLoader from "../../services/issueHistoryLoader";
import { issueParsers } from '../../parsers/issueParsers';

type LookupSymbolProps = {
  onSymbol: (symbol: string) => void;
};


/**
 * Presents the interface to lookup a symbol.
 * @param props Properties from parent
 * @returns Lookup symbol element
 */
export default function LookupSymbol(props: LookupSymbolProps): React.ReactElement {
  const symbolRef = React.useRef<HTMLInputElement>(null);
  const [symbolInput, setSymbolInput] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<SymbolSearch | null>(null);
  let symbolTimeout: NodeJS.Timeout | null;

  /**
   * Notifies that a symbol has been selected.
   * @param symbol Selected symbol
   */
  const selectSymbol = (symbol: string) => {
    props.onSymbol(symbol);
    setSymbolInput("");
    setSearchResults(null);
  }

  /**
   * Handles change of input symbol.
   * @param changed Changed value
   * @param updateNow Indicates search should be updated right away
   */
  const onInputChange = (changed: string, updateNow?: boolean) => {
    // Display the change
    setSymbolInput(changed);
    setSearchResults(null);

    // Prepare symbol lookup
    if (!!symbolTimeout) {
      clearTimeout(symbolTimeout);
    }

    // Wait to make sure the user is done typing
    symbolTimeout = !!changed.length
      ? setTimeout(onSearchSymbol, !updateNow ? 1000 : 1)
      : null;
  }

  /**
   * Handles keyboard input looking for Enter.
   * @param event Keyboard input event details
   */
  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && symbolInput.length > 0) {
      selectSymbol(symbolInput);
    }
  }

  /**
   * Performs symbol search when the user stops typing.
   */
  const onSearchSymbol = () => {
    // Async functions have to use a ref to get the current value
    const searchInput = symbolRef.current?.value ?? "";

    // Trivial check
    if (!searchInput.length) {
      // Nothing to look up
      setSearchResults(null);
      return;
    }

    // Perform lookup
    IssueHistoryLoader.searchSymbol(searchInput)
    .then(res => {
      const currentInput = symbolRef.current?.value;
      if (res && res.searchText === currentInput) {
        const searchLen = res.searchText.length;
        setSearchResults(res);
        symbolRef.current?.focus();
        symbolRef.current?.setSelectionRange(searchLen, searchLen);
      }
    });
  }

  /**
   * Handles selection from search results.
   * @param selected Selected from search results
   */
  const onSelectSearch = (selected: string) => {
    const lookup = issueParsers.select(selected);
    if (lookup && !lookup.isComplete) {
      onInputChange(lookup.symbol, true);
    } else {
      // Fetch history for the symbol
      selectSymbol(selected);
    }
  }

  /**
   * Renders the symbol search results, if any.
   * @returns Symbol search results nodes
   */
  const renderSearchResults= (): React.ReactNode => {
    // Get the search results
    if (!searchResults) {
      return null;
    }

    // Are there no matches?
    if (searchResults.results.length === 0) {
      return (
        <div className="mahut-lookup-list">
          <span>No matches for symbol '{ searchResults.searchText }'</span>
        </div>
      );
    }

    // Format results
    return (
      <div className="mahut-lookup-list">
        { searchResults.results.map(result =>
            <React.Fragment key={ result.symbol }>
              <span onClick={() => onSelectSearch(result.symbol)}>{ result.symbol }</span>
              <span onClick={() => onSelectSearch(result.symbol)}>{ result.name }</span>
            </React.Fragment>
          )
        }
      </div>
    );
  }

  // Returns the lookup markup
  return (
    <div className="mahut-lookup">
      <div>Lookup Symbol:&nbsp;</div>
      <div className="mahut-lookup-popup">
        <input
          ref={symbolRef}
          type="text"
          title="Symbol"
          value={symbolInput}
          onChange={(e) => onInputChange(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
        />
        { renderSearchResults() }
      </div>
      <span
        className={"material-icons" + (symbolInput.length === 0 ? " lookup-disabled" : "")}
        onClick={() => selectSymbol(symbolInput)}
      >search</span>
    </div>
  );
}
