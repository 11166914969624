import IssueList from "../../entities/issueList";
import IssueSymbolParser from "./issueSymbolParser";

/**
 * Database of forex issues.
 */
const forexIssues: IssueList = {
  "AED": { name: "United Arab Emirates Dirham" },
  "AFN": { name: "Afghan Afghani" },
  "ALL": { name: "Albanian Lek" },
  "AMD": { name: "Armenian Dram" },
  "ANG": { name: "Netherlands Antillean Guilder" },
  "AOA": { name: "Angolan Kwanza" },
  "ARS": { name: "Argentine Peso" },
  "AUD": { name: "Australian Dollar" },
  "AWG": { name: "Aruban Florin" },
  "AZN": { name: "Azerbaijani Manat" },
  "BAM": { name: "Bosnia-Herzegovina Convertible Mark" },
  "BBD": { name: "Barbadian Dollar" },
  "BDT": { name: "Bangladeshi Taka" },
  "BGN": { name: "Bulgarian Lev" },
  "BHD": { name: "Bahraini Dinar" },
  "BIF": { name: "Burundian Franc" },
  "BMD": { name: "Bermudan Dollar" },
  "BND": { name: "Brunei Dollar" },
  "BOB": { name: "Bolivian Boliviano" },
  "BRL": { name: "Brazilian Real" },
  "BSD": { name: "Bahamian Dollar" },
  "BTN": { name: "Bhutanese Ngultrum" },
  "BWP": { name: "Botswanan Pula" },
  "BZD": { name: "Belize Dollar" },
  "CAD": { name: "Canadian Dollar" },
  "CDF": { name: "Congolese Franc" },
  "CHF": { name: "Swiss Franc" },
  "CLF": { name: "Chilean Unit of Account UF" },
  "CLP": { name: "Chilean Peso" },
  "CNH": { name: "Chinese Yuan Offshore" },
  "CNY": { name: "Chinese Yuan", commonOrder: 5 },
  "COP": { name: "Colombian Peso" },
  "CUP": { name: "Cuban Peso" },
  "CVE": { name: "Cape Verdean Escudo" },
  "CZK": { name: "Czech Republic Koruna" },
  "DJF": { name: "Djiboutian Franc" },
  "DKK": { name: "Danish Krone" },
  "DOP": { name: "Dominican Peso" },
  "DZD": { name: "Algerian Dinar" },
  "EGP": { name: "Egyptian Pound" },
  "ERN": { name: "Eritrean Nakfa" },
  "ETB": { name: "Ethiopian Birr" },
  "EUR": { name: "Euro", commonOrder: 3, },
  "FJD": { name: "Fijian Dollar" },
  "FKP": { name: "Falkland Islands Pound" },
  "GBP": { name: "British Pound Sterling", commonOrder: 2 },
  "GEL": { name: "Georgian Lari" },
  "GHS": { name: "Ghanaian Cedi" },
  "GIP": { name: "Gibraltar Pound" },
  "GMD": { name: "Gambian Dalasi" },
  "GNF": { name: "Guinean Franc" },
  "GTQ": { name: "Guatemalan Quetzal" },
  "GYD": { name: "Guyanaese Dollar" },
  "HKD": { name: "Hong Kong Dollar" },
  "HNL": { name: "Honduran Lempira" },
  "HRK": { name: "Croatian Kuna" },
  "HTG": { name: "Haitian Gourde" },
  "HUF": { name: "Hungarian Forint" },
  "ICP": { name: "Internet Computer" },
  "IDR": { name: "Indonesian Rupiah" },
  "ILS": { name: "Israeli New Sheqel" },
  "INR": { name: "Indian Rupee" },
  "IQD": { name: "Iraqi Dinar" },
  "IRR": { name: "Iranian Rial" },
  "ISK": { name: "Icelandic Krona" },
  "JEP": { name: "Jersey Pound" },
  "JMD": { name: "Jamaican Dollar" },
  "JOD": { name: "Jordanian Dinar" },
  "JPY": { name: "Japanese Yen", commonOrder: 4 },
  "KES": { name: "Kenyan Shilling" },
  "KGS": { name: "Kyrgystani Som" },
  "KHR": { name: "Cambodian Riel" },
  "KMF": { name: "Comorian Franc" },
  "KPW": { name: "North Korean Won" },
  "KRW": { name: "South Korean Won" },
  "KWD": { name: "Kuwaiti Dinar" },
  "KYD": { name: "Cayman Islands Dollar" },
  "KZT": { name: "Kazakhstani Tenge" },
  "LAK": { name: "Laotian Kip" },
  "LBP": { name: "Lebanese Pound" },
  "LKR": { name: "Sri Lankan Rupee" },
  "LRD": { name: "Liberian Dollar" },
  "LSL": { name: "Lesotho Loti" },
  "LYD": { name: "Libyan Dinar" },
  "MAD": { name: "Moroccan Dirham" },
  "MDL": { name: "Moldovan Leu" },
  "MGA": { name: "Malagasy Ariary" },
  "MKD": { name: "Macedonian Denar" },
  "MMK": { name: "Myanma Kyat" },
  "MNT": { name: "Mongolian Tugrik" },
  "MOP": { name: "Macanese Pataca" },
  "MRO": { name: "Mauritanian Ouguiya (pre-2018)" },
  "MRU": { name: "Mauritanian Ouguiya" },
  "MUR": { name: "Mauritian Rupee" },
  "MVR": { name: "Maldivian Rufiyaa" },
  "MWK": { name: "Malawian Kwacha" },
  "MXN": { name: "Mexican Peso" },
  "MYR": { name: "Malaysian Ringgit" },
  "MZN": { name: "Mozambican Metical" },
  "NAD": { name: "Namibian Dollar" },
  "NGN": { name: "Nigerian Naira" },
  "NOK": { name: "Norwegian Krone" },
  "NPR": { name: "Nepalese Rupee" },
  "NZD": { name: "New Zealand Dollar" },
  "OMR": { name: "Omani Rial" },
  "PAB": { name: "Panamanian Balboa" },
  "PEN": { name: "Peruvian Nuevo Sol" },
  "PGK": { name: "Papua New Guinean Kina" },
  "PHP": { name: "Philippine Peso" },
  "PKR": { name: "Pakistani Rupee" },
  "PLN": { name: "Polish Zloty" },
  "PYG": { name: "Paraguayan Guarani" },
  "QAR": { name: "Qatari Rial" },
  "RON": { name: "Romanian Leu" },
  "RSD": { name: "Serbian Dinar" },
  "RUB": { name: "Russian Ruble" },
  "RUR": { name: "Old Russian Ruble" },
  "RWF": { name: "Rwandan Franc" },
  "SAR": { name: "Saudi Riyal" },
  "SBD": { name: "Solomon Islands Dollar" },
  "SCR": { name: "Seychellois Rupee" },
  "SDG": { name: "Sudanese Pound" },
  "SDR": { name: "Special Drawing Rights" },
  "SEK": { name: "Swedish Krona" },
  "SGD": { name: "Singapore Dollar" },
  "SHP": { name: "Saint Helena Pound" },
  "SLL": { name: "Sierra Leonean Leone" },
  "SOS": { name: "Somali Shilling" },
  "SRD": { name: "Surinamese Dollar" },
  "SYP": { name: "Syrian Pound" },
  "SZL": { name: "Swazi Lilangeni" },
  "THB": { name: "Thai Baht" },
  "TJS": { name: "Tajikistani Somoni" },
  "TMT": { name: "Turkmenistani Manat" },
  "TND": { name: "Tunisian Dinar" },
  "TOP": { name: "Tongan Pa'anga" },
  "TRY": { name: "Turkish Lira" },
  "TTD": { name: "Trinidad and Tobago Dollar" },
  "TWD": { name: "New Taiwan Dollar" },
  "TZS": { name: "Tanzanian Shilling" },
  "UAH": { name: "Ukrainian Hryvnia" },
  "UGX": { name: "Ugandan Shilling" },
  "USD": { name: "United States Dollar", commonOrder: 1 },
  "UYU": { name: "Uruguayan Peso" },
  "UZS": { name: "Uzbekistan Som" },
  "VND": { name: "Vietnamese Dong" },
  "VUV": { name: "Vanuatu Vatu" },
  "WST": { name: "Samoan Tala" },
  "XAF": { name: "CFA Franc BEAC" },
  "XCD": { name: "East Caribbean Dollar" },
  "XDR": { name: "Special Drawing Rights" },
  "XOF": { name: "CFA Franc BCEAO" },
  "XPF": { name: "CFP Franc" },
  "YER": { name: "Yemeni Rial" },
  "ZAR": { name: "South African Rand" },
  "ZMW": { name: "Zambian Kwacha" },
  "ZWL": { name: "Zimbabwean Dollar" }
};

/** Parses symbols for forex issues. */
const forexParser = new IssueSymbolParser("Forex", "FX", forexIssues, forexIssues);

export { forexIssues, forexParser };