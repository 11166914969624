import ModelIndicator from "../../services/ModelIndicator";
import "./currentIndicator.css";

export default function CurrentIndicator(props: {indicator?: ModelIndicator}) {
  const buySell = !!props.indicator ? (props.indicator.isBuy ? "buy" : "sell") : "";
  const icon = !!props.indicator ? (props.indicator.isBuy ? "trending_up" : "trending_down") : "trending_flat";
  return (
    <div className={`current-indicator ${buySell}`}>
      <span className="material-icons">{ icon }</span>
    </div>
  );
}