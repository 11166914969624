import React, { Fragment } from 'react';
import ModelIndicator from '../../../services/ModelIndicator';
import ChromeService from '../../../services/chromeService';
import { Bar } from 'react-chartjs-2';
import { IndicatorPeriod } from '../mahutDemo.types';
import ModelReportBuilder from '../../../services/ModelReportBuilder';


/** Specifies the market performance chart's properties. */
type MarketPerformanceChartProps = {
  period: IndicatorPeriod;
  indicator: ModelIndicator;
}


/**
 * Displays the market performance chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function MarketPerformanceChart(props: MarketPerformanceChartProps): React.ReactElement {
  const chartData = React.useMemo(() => {
    // Generate report
    const report = ModelReportBuilder.build(props.indicator, props.period);

    // Determine chart colors for market bars
    const marketColor = (ctx: any): string => {
      const value: number = ctx.raw;
      return (
        value < 0 ? ChromeService.graphColor.marketNegative :
        value > 0 ? ChromeService.graphColor.marketPositive :
        ChromeService.graphColor.neutral
      );
    }

    // Determine chart colors for model bars
    const modelColor = (ctx: any): string => {
      const value: number = ctx.raw;
      return (
        value < 0 ? ChromeService.graphColor.negative :
        value > 0 ? ChromeService.graphColor.positive :
        ChromeService.graphColor.neutral
      );
    }

    // Deterine colors on pivot chart
    const pivotColor = (ctx: any): string => {
      console.debug(ctx);
      return ctx.dataIndex === 1 ? modelColor(ctx) : marketColor(ctx);
    }

    // Determine the pivot chart properties
    const overallData = {
      labels: ["Market Performance", "Model Performance"],
      datasets:[{
        label: "Performance",
        data: [report.summary.marketPct * 100, report.summary.modelPct * 100],
        backgroundColor: pivotColor,
      }],
    };
    const overallOptions = {
      indexAxis: "y" as const,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    // Determine the period chart properties
    const periodData = (report.summaryBreakdown.length === 0) ? undefined : {
      labels: report.summaryBreakdown.map(rep => rep.label),
      datasets:[{
        label: "Market Performance",
        data: report.summaryBreakdown.map(rep => rep.marketPct * 100),
        backgroundColor: marketColor,
      }, {
        label: "Model Performance",
        data: report.summaryBreakdown.map(rep => rep.modelPct * 100),
        backgroundColor: modelColor,
      }],
    };
    const periodOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    return {overallData, overallOptions, periodData, periodOptions};
  }, [props.indicator, props.period]);
  if (!chartData.periodData) {
    // No period data
    return (
      <div style={{position: "absolute", inset: "4pt", display: "flex", flexDirection: "column", alignItems: "center", height: "100%"}}>
        <Bar data={chartData.overallData} options={chartData.overallOptions} />
      </div>
    );
  }
  return (
    <Fragment>
      <div style={{position: "absolute", inset: "4pt", display: "flex", flexDirection: "column", alignItems: "center", height: "50%"}}>
        <Bar data={chartData.overallData} options={chartData.overallOptions} />
      </div>
      <div style={{position: "absolute", inset: "4pt", display: "flex", flexDirection: "column", alignItems: "center", height: "50%", top: "50%"}}>
        <Bar data={chartData.periodData} options={chartData.periodOptions} />
      </div>
    </Fragment>
  );
}
